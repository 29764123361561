import Vue from 'vue'
import Vuex from 'vuex'

import texts from './texts'
import dropdowns from './dropdowns'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    form: {
      car: null,
      user: {
        title: '',
        name: '',
        last_name: '',
        email: '',
        telephone: '',
        street: '',
        street_number: '',
        postal_number: '',
        city: '',
        isCustomerCompany: false,
        companyName: ''
      },
      dealer: null,
      appointment: {
        date: '',
        time: ''
      },
      service: {
        notes: ''
      },
      formName: '',
      target: '',
      consent: true,
      gdpr: {
        nakupVozila: false,
        poprodajniIzdelki: true,
        personalizacija: false,
        ePosta: null,
        sms: null,
        telefon: null,
        obicajnaPosta: null,
        terms: false
      }
    },
    // VALIDATION
    validation: {
      car: true,
      title: false,
      name: false,
      lastName: false,
      email: false,
      confirmEmail: true,
      telephone: true,
      street: true,
      streetNumber: true,
      postalNumber: true,
      dealer: true,
      city: true,
      companyName: true,
      gdprEmail: false
    },
    buttons: {
      ponudbeZaNakupBtn: {
        value: false,
        error: false
      },
      poprodajniIzdelkiBtn: {
        value: null,
        error: false
      },
      personalizacijaBtn: {
        value: null,
        error: false
      },
      complyBtn: {
        value: true,
        error: false
      }
    },
    comply: true,
    submitted: false,
    atLeastOneTypeOfSending: true,
    gdprNakup: false,
    gdprPoprodajniIzdelki: false,
    gdprPersonalizacija: false,
    allChannelsNull: true  
  },
  getters: {
    isCustomerCompany: state => {
      return state.form.user.isCustomerCompany
    },
    companyName: state => {
      return state.form.user.companyName
    },
    carModel: state => {
      return state.form.car.model
    },
    title: state => {
      return state.form.user.title
    },
    name: state => {
      return state.form.user.name
    },
    last_name: state => {
      return state.form.user.last_name
    },
    email: state => {
      return state.form.user.email
    },
    telephone: state => {
      return state.form.user.telephone
    },
    street: state => {
      return state.form.user.street
    },
    street_number: state => {
      return state.form.user.street_number
    },
    postal_number: state => {
      return state.form.user.postal_number
    },
    city: state => {
      return state.form.user.city
    },
    testDrive: state => {
      return state.form.testdrive
    },
    gdprEmail: state => {
      return state.form.gdpr.ePosta
    },
    gdprSms: state => {
      return state.form.gdpr.sms
    },
    gdprTelefon: state => {
      return state.form.gdpr.telefon
    },
    gdprObicajnaPosta: state => {
      return state.form.gdpr.obicajnaPosta
    },
  },
  mutations: {
    updateIsCustomerCompany: (state, data) => {
      state.form.user.isCustomerCompany = data
    },
    updateCompanyName: (state, data) => {
      state.form.user.companyName = data
    },
    updateCarModel: (state, data) => {
      state.form.car.model = data
    },
    updateTitle: (state, data) => {
      state.form.user.title = data
    },
    updateName: (state, data) => {
      state.form.user.name = data
    },
    updateLastName: (state, data) => {
      state.form.user.last_name = data
    },
    updateEmail: (state, data) => {
      state.form.user.email = data
    },
    updateTelephone: (state, data) => {
      state.form.user.telephone = data
    },
    updateStreet: (state, data) => {
      state.form.user.street = data
    },
    updateStreetNumber: (state, data) => {
      state.form.user.street_number = data
    },
    updatePostalNumber: (state, data) => {
      state.form.user.postal_number = data
    },
    updateCity: (state, data) => {
      state.form.user.city = data
    },
    updateTestDrive: (state, data) => {
      state.form.testdrive = data
    },
    updateGdprEmail: (state, data) => {
      state.form.gdpr.ePosta = data
    },
    updateGdprSms: (state, data) => {
      state.form.gdpr.sms = data
    },
    updateGdprTelefon: (state, data) => {
      state.form.gdpr.telefon = data
    },
    updateGdprObicajnaPosta: (state, data) => {
      state.form.gdpr.obicajnaPosta = data
    },
    // updateCarVinNumber: (state, data) => {
    //   state.form.car.vin = data
    // }
  },
  modules: {
    dropdowns,
    texts
  }
})
