// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'document-register-element/build/document-register-element'
import Vue from 'vue'
// import App from './App'
import App from './components/UserWidget'
// import router from './router'
import store from './store'
import Vuelidate from 'vuelidate'
import VueResource from 'vue-resource'

import vueCustomElement from 'vue-custom-element'
import _ from 'lodash'

Vue.use(Vuelidate)
Vue.use(VueResource)

Vue.use(vueCustomElement)

Vue.config.productionTip = false

/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   router,
//   store,
//   components: { App },
//   template: '<App/>'
// })

App.store = store
// App.router = router
Vue.customElement('user-info-form-widget', App)