import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    models: [
      {
        value: 'CL4',
        name: 'CLIO',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/clio.jpg'
      },
      {
        value: 'CK4',
        name: 'CLIO Grandtour',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/clio-grandtour.jpg'
      },
      /*{
        value: 'CL4_RS',
        name: 'CLIO R.S.',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/renault-sport/clio-rs.jpg'
      },*/
      {
        value: 'FEH',
        name: 'KADJAR',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/kadjar.jpg'
      },
      {
        value: "FEH_PH2",
        name: "Novi KADJAR",
        image:
          "http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/novi_kadjar.jpg"
      },
      {
        value: 'KU2',
        name: 'KANGOO Express',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/lahka-gospodarska-vozila/kangoo-express.jpg'
      },
      {
        value: 'MAU',
        name: 'MASTER',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/lahka-gospodarska-vozila/master-furgon.jpg'
      },
      {
        value: 'MB4',
        name: 'MEGANE',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/megane.jpg'
      },
      {
        value: 'MK4',
        name: 'MEGANE Grandtour',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/megane-grandtour.jpg'
      },
      /*{
        value: 'MB4_GT',
        name: 'MEGANE GT',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/renault-sport/megane-gt.jpg'
      },*/
      /*{
        value: 'MK4_GT',
        name: 'MEGANE Grandtour GT',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/renault-sport/megane-gt.jpg'
      },*/
      {
        value: 'FF1',
        name: 'MEGANE GrandCoupé',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/megane-grandcoupe.jpg'
      },
      {
        value: 'BFB_RS',
        name: 'Novi MEGANE R.S.',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/renault-sport/megane-rs.jpg'
      },
      {
        value: '87J',
        name: 'CAPTUR',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/captur.jpg'
      },
      {
        value: '87J_IP',
        name: 'CAPTUR INITIALE PARIS',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/captur.jpg'
      },
      {
        value: 'ZGH',
        name: 'KOLEOS',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/koleos.jpg'
      },
      {
        value: 'ZGH',
        name: 'KOLEOS INITIALE PARIS',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/koleos.jpg'
      },
      {
        value: 'SC4',
        name: 'SCENIC',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/scenic.jpg'
      },
      {
        value: 'FDL',
        name: 'TALISMAN',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/talisman.jpg'
      },
      {
        value: 'FDL_IP',
        name: 'TALISMAN INITIALE PARIS',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/talisman.jpg'
      },
      {
        value: 'TRU',
        name: 'TRAFIC',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/lahka-gospodarska-vozila/trafic-furgon.jpg'
      },
      {
        value: 'TRP',
        name: 'TRAFIC Passenger',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/trafic-passenger.jpg'
      },
      {
        value: 'TW3',
        name: 'TWINGO',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/osebna-vozila/twingo.jpg'
      },
      {
        value: 'TW3_GT',
        name: 'TWINGO GT',
        image: 'http://posebnaponudba.renault.si/assets/car-detail/renault-sport/twingo-gt.jpg'
      }
    ],
    dealers: [
      {
        'dealerId': '89100505',
        'suggestedName': 'AUTO CAR VIDAKOVIĆ'
      },
      {
        'dealerId': '89150003',
        'suggestedName': 'AUTO CENTAR KRUNIĆ'
      },
      {
        'dealerId': '89150018',
        'suggestedName': 'AUTO KUĆA FRANCUZ'
      },
      {
        'dealerId': '89150075',
        'suggestedName': 'AUTO KUĆA KOMPRESOR'
      },
      {
        'dealerId': '89150187',
        'suggestedName': 'AUTO KUĆA SEKULIĆ - NOVI SAD'
      },
      {
        'dealerId': '89100506',
        'suggestedName': 'AUTO KUĆA SEKULIĆ - VETERNIK'
      },
      {
        'dealerId': '89150001',
        'suggestedName': 'NOVI B AUTO'
      },
      {
        'dealerId': '89150076',
        'suggestedName': 'BIBO CAR'
      },
      {
        'dealerId': '89150020',
        'suggestedName': 'HIT AUTO'
      },
      {
        'dealerId': '89150167',
        'suggestedName': 'JEVTOVIĆ AUTO'
      },
      {
        'dealerId': '89150176',
        'suggestedName': 'JUGO SPORT'
      },
      {
        'dealerId': '89150058',
        'suggestedName': 'PSC VUKOVIĆ'
      },
      {
        'dealerId': '89150041',
        'suggestedName': 'RENO SAVA - BEOGRAD'
      },
      {
        'dealerId': '89150168',
        'suggestedName': 'RENO SAVA - PANČEVO'
      },
      {
        'dealerId': '89150183',
        'suggestedName': 'STOJANOV AUTO'
      }
    ],
    titles: {
      renault: {
        si: ["G.", "Ga."],
        hr: ['Gospodin', 'Gospođa'],
        rs: ['Gdin', 'Gdja']
      },
      dacia: {
        si: ["G.", "Ga."],
        hr: ['Gospodin', 'Gospođa'],
        rs: ['Gdin', 'Gdja']
      },
      nissan: {
        si: ["G.", "Ga."],
        hr: ['Gospodin', 'Gospođa'],
        rs: ['Gdin', 'Gdja']
      }
    },
    times: [
      '8:00',
      '9:00',
      '10:00',
      '11:00',
      '12:00',
      '13:00',
      '14:00',
      '15:00',
      '16:00',
      '17:00'
    ],
    monthsLong: [
      'Januar',
      'Februar',
      'Mart',
      'April',
      'Maj',
      'Jun',
      'Jul',
      'Avgust',
      'Septembar',
      'Oktobar',
      'Novembar',
      'Decembar'
    ],
    monthsShort: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'Maj',
      'Jun',
      'Jul',
      'Avg',
      'Sep',
      'Okt',
      'Nov',
      'Dec'
    ],
    dateNamesMin: [
      'ne',
      'po',
      'ut',
      'rs',
      'če',
      'pe',
      'su'
    ],
    dateNamesShort: [
      'ned',
      'pon',
      'uto',
      'sre',
      'čet',
      'pet',
      'sub'
    ],
    dateNamesLong: [
      'nedelja',
      'ponedeljak',
      'utorak',
      'sreda',
      'četvrtak',
      'petak',
      'subota'
    ],
    services: [
      {
        'name': 'Redovan servisni pregled',
        'value': 'RedovanServisniPregled'
      },
      {
        'name': 'Dodatna oprema',
        'value': 'DodatnaOprema'
      },
      {
        'name': 'Informacije o paketima',
        'value': 'InformacijeoPaketima'
      },
      {
        'name': 'Ugovor o održavanju',
        'value': 'UgovoroOdrzavanju'
      },
      {
        'name': 'Popravka karoserije',
        'value': 'PopravkaKaroserije'
      },
      {
        'name': 'Gume',
        'value': 'Gume'
      },
      {
        'name': 'Vetrobransko staklo',
        'value': 'VetrobranskoStaklo'
      },
      {
        'name': 'Ostalo',
        'value': 'Ostalo'
      }
    ]
  },
  getters: {
  },
  mutations: {
  }
})
