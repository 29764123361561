import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    title: 'Ponuda za kupovinu vozila',
    subtitle: 'Interesuje me vozilo',
    formName: 'HELIOS KUPOVINA FORMA',
    target: 'WEB FORM',
    car: {
      title: 'Podaci o vozilu za Renault uslugu koju želite',
      model: {
        title: 'Vozilo',
        select: 'Izaberite vozilo',
        error: 'Izabira vozila je obavezna'
      },
      age: {
        title: 'Starost vozila',
        select: 'Izaberite godište vozila'
      },
      vin: {
        title: 'Broj šasije',
        placeholder: 'UU1BR2....'
      },
      milage: {
        title: 'Broj pređenih kilometara'
      },
      testDrive: 'Želim da znam da li mogu da dobijem zamensko vozilo.'
    },
    services: {
      title: 'Tip usluge',
      service: {
        title: 'Izaberite jednu od naših usluga',
        select: 'Odaberite uslugu',
        error: 'Trebate odabrati jednu od usluga'
      },
      notes: {
        title: 'Želim detaljnije da opišem moj upit.'
      }
    },
    dealer: {
      title: 'Odabir servisa i termina',
      titleDealer: 'Izbira termina',
      dealer: {
        title: 'Ovlašćeni prodavac',
        select: 'Izaberite prodavaca',
        error: 'Izbor prodavca je obavezan.'
      },
      date: {
        title: 'Datum',
        error: 'Unesite datum.'
      },
      time: {
        title: 'Vreme',
        select: 'Izabrati željeno vreme',
        error: 'Sat je potrebno izabrati.',
        helpText: 'Izabrani termin je informativnog karaktera. O raspoloživom terminu će vas obavestiti izabrani distributer.'
      }
    },
    user: {
      title: 'Moji podaci',
      gender: {
        title: 'Oslovljavanje',
        select: 'Izaberite',
        error: 'Unos oslovljavanja je obavezan.'
      },
      companyName: {
        title: 'Kompanija',
        error: 'Unos imena kompanije je obavezan.'
      },
      name: {
        title: 'Ime',
        error: 'Upišite svoje ime.'
      },
      lastName: {
        title: 'Prezime',
        error: 'Upišite prezime.'
      },
      business: {
        title: 'Firma'
      },
      email: {
        title: 'E-adresa',
        error: 'Upišite e-mail adresu.'
      },
      confirmEmail: {
        title: 'Potvrdite e-adresu',
        error: 'Molimo vas da proverite da li ste ispravno upisali e-adresu kako bismo vas lakše kontaktirali i pozvali na probnu vožnju.'
      },
      address: {
        title: 'Ulica',
        error: 'Upišite naziv ulice.'
      },
      houseNumber: {
        title: 'Kućni broj',
        error: 'Upišite kućni broj.'
      },
      postalCode: {
        title: 'Poštanski broj',
        error: 'Upišite svoj poštanski broj.'
      },
      city: {
        title: 'Grad',
        error: 'Upišite naziv svog grada.'
      },
      phone: {
        title: 'Broj telefona',
        error: 'Zapišite telefonski broj bez razmaka.',
        helpText: 'Zapišite telefonski broj bez razmaka'
      },
      testDrive: 'Želim probnu vožnju.',
      additional: 'Želim detaljnije da opišem moj upit'
    },
    gdpr: {
      title: 'Potvrda',
      error: 'Označili ste DA kod najmanje jedne vrste obaveštenja zato vas molimo da odaberete najmanje jedan način obaveštavanja.',
      terms: {
        title: 'Pročitala/Pročitao sam pravne informacije i slažem se sa njima',
        error: 'Za nastavak se morate složiti sa pravnim obaveštanjima.',
        helpText: 'Posredovanjem ličnih podataka dozvoljavam da KEOS, d.o.o. sam ili preko izabranog ovlašćenog distributera Renault upotrebljava lične podatke, navedene u gornjem obrascu, u svrhu sprovođenja ponude u vremenskom periodu potrebnom za izvršenje usluge, odnosno najduže 3 godine.',
        helpText2: 'Vođenje, kontrola i upravljanje bazom podataka o ličnosti kao i posredovanje podataka trećim licima precizno određuje Pravilnik ZLP kompanije KEOS, d.o.o. i Katalog podataka o ličnosti, kojim upravlja KEOS, d.o.o. Više o opštim informacijama o zaštiti ličnih podataka mogu da pročitam <a href="http://www.renault.rs/opste-zlp.html" target="_blank">OVDE</a>. U slučaju zloupotrebe mojih podataka mogu da se obratim kompaniji Keos d.o.o. na e-adresu: <a href="mailto:zlp.renault@keos.rs">zlp.renault@keos.rs</a>. Imam pravo na sudsku zaštitu i obračanje Povereniku za informacije od javnog značaja i zaštitu podataka o ličnosti.'
      },
      comply: {
        title: 'Da li želite da vam šaljemo poruke o aktuelnim ponudama i novostima?',
        yes: 'da',
        no: 'ne',
        error: 'Molimo označite svoj odabir.',
        agree: 'SAGLASNOST ZA OBAVEŠTAVANJE'
      },
      types: {
        title: 'SAGLASNOST ZA OBAVEŠTAVANJE',
        description: 'Označavanjem donjih kategorija dozvoljavam da KEOS, d.o.o. i izabrani ovlaščeni distributer Renault koriste moje podatke za slanje prilagođenih poruka, vezanih za potraživanja iz gornjeg obrasca, kao i da ih čuvaju i obrađuju u skladu sa Politikom o zaštiti podataka o ličnosti (važeći dokument je objavljen <a href="http://www.renault.rs/opste-zlp.html" target="_blank">OVDE</a>). Saglasan sam da moje podatke obrađujete i da mi šaljete poruke samo za one svrhe koje ću označiti ispod.',
        purchase: {
          title: 'Ponude za kupovinu vozila',
          tooltips: 'Želim da primam prilagođene prodajne ponude za kupovinu vozila.',
          error: 'Molimo označite svoj odabir.'
        },
        afs: {
          title: 'Prilagođene ponude',
          tooltips: 'Želim da dobijem ponude za kupovinu vozila i  postprodajnih proizvoda i usluga koji su personalizovani na osnovu mojih podataka koje sam vam dao; i informacije o personalizovanim pogodnostima, događajima i drugim aktuelnim vestima.<br><br>Obrađeni lični podaci u tu svrhu: ime, prezime, adresa, broj telefona, e-mail adresa, socio-demografski podaci, podaci o istorijskim kupovnim aktivnostima.',
          error: 'Molimo označite svoj odabir.'
        },
        personalization: {
          title: 'Profilisane ponude',
          tooltips: 'Dopuštam vam da prikupljate i analizirate moje lične podatke, prethodne kupovine i kupovne navike, moje interese, kako bih stvorili lični profil,  na osnovu kojeg mi šaljete personalizovane poruke i ponude.<br><br>Lični podaci obrađeni u tu svrhu: ime, prezime, adresa, broj telefona, e-mail adresa, socio-demografski podaci, podaci o prošloj aktivnosti kupovine, onlajn kolačići, podaci o geolokaciji.',
          error: 'Molimo označite svoj odabir.'
        }
      },
      channel: {
        title: '',
        description: 'Dozvoljavam da me KEOS d.o.o. i izabrani ovlaščeni distributer Renault obaveštavate na izabrane načine koje ću označiti ispod.',
        email: 'E-ADRESA',
        sms: 'SMS',
        phone: 'TELEFON',
        mail: 'OBIČNA POŠTA',
        footer: 'Moja saglasnost za obaveštavanje se odnosi na ovo potraživanje i nije vezana za druge sisteme u kojima se mogu nalaziti moji lični podaci.<br><br>Svoju saglasnost mogu da otkažem u bilo kom trenutku tako što svoju odluku pisano ili usmeno saopštim ovlašćenom distributeru Renault ili kompaniji KEOS, d.o.o. na e-adresu: <a href="mailto:renault.kontakt@keos.rs">renault.kontakt@keos.rs</a>.<br><br>Za podatke, koje sam dostavio(la) rukovaocu podataka, mogu da zahtevam: ispravku podataka, brisanje podataka (osim ako je zahtev u suprotnosti sa važećim zakonodavstvom), uvid u podatke i izvor podataka kao i ograničenje upotrebe podataka. Rukovalac moje lične podatke prikuplja i obrađuje do opoziva, ili najduže 30 godina, u skladu sa odredbama zakonodavstva o zaštiti podataka o ličnosti. Vođenje, kontrola i upravljanje bazom podataka ličnosti kao i posredovanje podataka trećim licima precizno određuje Pravilnik ZLP kompanije KEOS, d.o.o. i Katalog podataka o ličnosti, kojima upravlja KEOS, d.o.o. Više o opštim informacijama o zaštiti ličnih podataka mogu da pročitam <a href="http://www.renault.rs/opste-zlp.html" target="_blank">OVDE</a>. U slučaju zloupotrebe mojih podataka mogu da se obratim kompaniji KEOS d.o.o. na e-adresu: <a href="mailto:zlp.renault@keos.rs">zlp.renault@keos.rs</a>. Imam pravo na sudsku zaštitu i obračanje Povereniku za informacije od javnog značaja i zaštitu podataka o ličnosti.'
      }
    },
    // CHECKBOX ERRORS
    checkboxErrors: {
      smsCheckboxError: 'Za izabrani način primanja SMS poruka obavezno je navesti broj telefona. Molimo da ispunite polje Telefon.​',
      telephoneCheckboxError: 'Za izabrani način primanja poruka telefonskim putem obavezno je navesti broj telefona. Molimo da ispunite polje Telefon.​',
      postaCheckboxError: 'Za izabrani način primanja poruka običnom poštom obavezno je navesti ulicu, kućni broj, poštanski broj i grad. Molimo da ispunite polja Ulica i kućni broj, Poštanski broj i Grad.​',
      emailCheckboxError: 'Za izabrani način primanja poruka e-poštom obavezno je navesti e-adresu. Molimo da ispunite polje E-adresa.​',
      emailError: 'Primanja poruka putem e-pošte je obavezan kanal.',
      emailClickedError: 'Ako ste kod vrste obaveštavanja  označili sa DA  primanje E-novosti,  polje E-ADRESA je obavezno.',
      allChannelsNoError: 'Ako ste kod vrste obaveštavanja označili NE, odabir načina komunikacije nije moguć.',
      channelsYesandTypesNoError: 'Označili ste sa DA bar jednu vrstu poruka i vas zato molimo da izaberete bar jedan način primanja obaveštenja.',
      noCheckboxSelectedError: 'Označili ste sa DA bar jednu vrstu poruka i vas zato molimo da izaberete bar jedan način primanja obaveštenja.'
    },

    //TEXTS FOR ALL BRANDS AND LANGUAGES
    alltexts: {

      renault: {
        si: {
          user: {
            title: 'Moji podatki',
            gender: {
              title: 'Naziv',
              select: 'Izberite',
              error: 'Izberite svoj naziv.'
            },
            naturalPerson:{
              title: 'Fizična oseba'
            },
            companyName: {
              title: 'Podjetje',
              error: 'Vpišite ime podjetja.'
            },
            name: {
              title: 'Ime',
              error: 'Vpišite svoje ime.'
            },
            lastName: {
              title: 'Priimek',
              error: 'Vpišite svoj priimek.'
            },
            business: {
              title: 'Podjetje'
            },
            email: {
              title: 'E-pošta',
              error: 'Vpišite svoj e-mail naslov.'
            },
            confirmEmail: {
              title: 'Potrdite e-pošto',
              error: 'Prosimo, preverite, da ste pravilno vpisali naslov e-pošte, da vas bomo lahko kontaktirali in povabili na testno vožnjo.'
            },
            address: {
              title: 'Ulica',
              error: 'Vpišite ulico, kjer prebivate.'
            },
            houseNumber: {
              title: 'Hišna številka',
              error: 'Vpišite hišno številko.'
            },
            postalCode: {
              title: 'Poštna številka',
              error: 'Vpišite poštno številko kraja, kjer prebivate.'
            },
            city: {
              title: 'Kraj',
              error: 'Vpišite kraj, kjer prebivate.'
            },
            phone: {
              title: 'Številka telefona',
              error: 'Zapišite številko telefona brez presledkov ali ločil.',
              placeholder: '',
              helpText: 'Zapišite številko telefona brez presledkov ali ločil.',
              helpText090: 'Telefonska številka ni veljavna.'
            }
          },
          gdpr: {
            title: 'Potrditev',
            error: 'Označili ste DA pri najmanj eni vrsti sporočil, zato prosimo izberite najmanj en način obveščanja.',
            terms: {
              title: 'Prebral/a sem in se strinjam s pravnim obvestilom',
              error: 'Strinjati se morate s pravnim obvestilom.',
              helpText: 'S posredovanjem osebnih podatkov dovoljujem, da GA Adriatic d.o.o., sam ali izbrani pooblaščeni trgovec uporabljata osebne podatke, navedene v zgornjem obrazcu, za namene izvedbe testne vožnje v časovnem obdobju, potrebnem za izvedbo storitve, oz. najdlje 3 leta.',
              helpText2: 'Vodenje, nadzor in upravljanje zbirke osebnih podatkov ter posredovanje podatkov tretjim natančno določata Pravilnik VOP podjetja GA Adriatic d.o.o. in Katalog osebnih podatkov, ki jih upravlja GA Adriatic d.o.o. Več o splošnih informacijah o varstvu podatkov si lahko preberem <a href="https://www.renault.si/splosno-vop" target="_blank">TUKAJ</a>. V primeru zlorabe mojih podatkov se lahko obrnem na GA Adriatic d.o.o. na e-naslov: <a href="mailto:vop.renault@renault.si">vop.renault@renault.si</a>'
            },
            comply: {
              title: 'Želite, da vam pošiljamo sporočila o aktualnih ponudbah in novostih?',
              yes: 'da',
              no: 'ne',
              error: 'Prosimo označite svoj izbor.',
              agree: 'Privolitev za obveščanje'
            },
            types: {
              title: '',
              description: 'Želim prejemati osebno prilagojene ponudbe za nakup vozil in poprodajnih izdelkov in storitev ter informacije o prilagojenih ugodnostih in dogodkih in druge aktualne novice od GA Adriatic d.o.o., ki na slovenskem trgu zastopa znamke Renault, Dacia, Nissan, Alpine in od pooblaščenih trgovcev teh znamk, pri katerih se nahajajo moji podatki.',
              purchase: {
                title: 'Ponudbe za nakup vozila',
                tooltips: 'Želim prejemati prilagojene prodajne ponudbe za nakup vozil.',
                error: 'Prosimo označite svoj izbor.'
              },
              afs: {
                title: 'Ponudbe za nakup poprodajnih izdelkov in storitev',
                tooltips: 'Želim prejemati prilagojene prodajne ponudbe za nakup nadomestnih delov, originalne dodatne opreme, servisnih storitev, pogodb o vzdrževanju, podaljšanem jamstvu in podobno.',
                error: 'Prosimo označite svoj izbor.'
              },
              personalization: {
                title: 'Personalizacija in profiliranje',
                tooltips: 'Dovolim, da me kontaktirate z namenom pridobivanja dodatnih osebnih podatkov (na primer spol, starost, letno prevožene razdalje, geografski podatki, izobrazba, poklic, število družinskih članov v gospodinjstvu, število otrok, prostočasne dejavnosti, uporaba interneta, pretekla lastništva vozil, razlogi za nakup vozila ter drugi podobni podatki), ki jih boste uporabili, da mi boste posredovali personalizirane ponudbe, ki ustrezajo mojim željam in potrebam. Sam(a) odločam, katere podatke vam bom zaupal(a) in v vsakem trenutku lahko zahtevam izbris teh podatkov.',
                error: 'Prosimo označite svoj izbor.'
              }
            },
            channel: {
              title: 'Način prejemanja sporočil',
              description: 'Dovoljujem, da me GA Adriatic d.o.o. sam ali izbrani pooblaščeni trgovec Renault obveščata na izbrane načine, ki jih bom označil(a) spodaj.',
              email: 'E-pošta',
              sms: 'SMS',
              phone: 'Telefon',
              mail: 'Običajna pošta',
              footer: 'Moja privolitev za obveščanje se nanaša na to povpraševanje in ni vezana na ostale sisteme, kjer se lahko nahajajo moji osebni podatki.<br><br>Svojo privolitev lahko kadarkoli prekličem tako, da svojo odločitev pisno ali ustno sporočim pooblaščenemu trgovcu Renault ali podjetju GA Adriatic d.o.o. na e-naslov: <a href="mailto:pisite.nam@renault.si">pisite.nam@renault.si</a>.<br><br>Za podatke, ki sem jih posredoval(a) upravljalcu podatkov, lahko zahtevam: popravek podatkov, izbris podatkov (razen, če je zahteva v nasprotju z veljavno zakonodajo), vpogled v podatke in izvor podatkov ter omejitev uporabe podatkov. Upravljavec moje osebne podatke zbira in obdeluje do preklica, oz. največ 30 let, skladno z določbami zakonodaje o varovanju osebnih podatkov. Vodenje, nadzor in upravljanje zbirke osebnih podatkov ter posredovanje podatkov tretjim natančno določata Pravilnik VOP podjetja GA Adriatic d.o.o. in Katalog osebnih podatkov, ki jih upravlja GA Adriatic d.o.o.. Več o splošnih informacijah o varstvu podatkov si lahko preberem <a href="https://www.renault.si/splosno-vop" target="_blank">TUKAJ</a>. V primeru zlorabe mojih podatkov se lahko obrnem na GA Adriatic d.o.o. na e-naslov: <a href="mailto:vop.renault@renault.si">vop.renault@renault.si</a>.'
            }
          },
          checkboxErrors: {
            smsCheckboxError: 'Za izbrani način prejemanja sporočil SMS je obvezen vnos telefonske številke. Prosimo, izpolnite polje Telefon zgoraj.',
            telephoneCheckboxError: 'Za izbrani način prejemanja sporočil TELEFON je obvezen vnos telefonske številke. Prosimo, izpolnite polje Telefon zgoraj.',
            postaCheckboxError: 'Za izbrani način prejemanja sporočil OBIČAJNA POŠTA je obvezen vnos naslova. Prosimo, izpolnite polja Ulica in hišna številka, Poštna številka in Pošta zgoraj.',
            emailCheckboxError: 'Za izbrani način prejemanja sporočil E-POŠTA je obvezen vnos naslova e-pošte. Prosimo, izpolnite polje E-pošta zgoraj.',
            emailError: 'Način prejemanja sporočil preko E-pošte je obvezen.',
            emailClickedError: 'Označili ste DA pri vrsti sporočila E-novice, zato je polje E-POŠTA obvezno.',
            allChannelsNoError: 'Ker ste pri vseh vrstah sporočil označili NE, izbira načina obveščanja ni mogoča.',
            channelsYesandTypesNoError: 'Označili ste DA pri najmanj eni vrsti sporočil, zato prosimo izberite najmanj en način obveščanja.',
            noCheckboxSelectedError: 'Označili ste DA pri najmanj eni vrsti sporočil, zato prosimo izberite najmanj en način obveščanja.'
          },
          legalInfoLabel: 'Seznanjen(a) sem z informacijami o obdelavi osebnih podatkov GA Adriatic d.o.o.',
          legalInfoDisclamer: 'Prosimo, obvezno preberite.',
          legalInfoText: `
          <div>
            <div>1. Upravljavec osebnih podatkov</div>
            <div>
            GA Adriatic d.o.o., Dunajska 22, 1000 Ljubljana, pravna oseba, ki na slovenskem trgu zastopa znamke Renault, Dacia, Nissan in Alpine ter pooblaščeni trgovci znamk, kjer se nahajajo vaši podatki (seznam je objavljen na <a href="https://www.renault.si/trgovci" target="_blank">www.renault.si/trgovci</a>) kot skupni upravljavci podatkov.
            </div>
          </div>
          <div>
            Za vsa vprašanja glede obdelave podatkov ali vaših pravic se obrnite na pooblaščeno osebo za zaščito podatkov: <a href="mailto:SI-Renault-vop@grandautomotive.eu">SI-Renault-vop@grandautomotive.eu</a>.
          </div>
          <div>
            <div>2. Namen obdelave osebnih podatkov</div>
            <div>
            GA Adriatic d.o.o. sam ali izbrani pooblaščeni trgovec uporabljata vaše osebne podatke, ki ste jih posredovali prek te spletne strani, za namen obdelave vaše zahteve iz tega obrazca, v roku, ki je potreben za izvedbo storitve. Vaši podatki se lahko uporabijo za anketiranje o zadovoljstvu, za pošiljanje komercialnih ponudb za nakup vozil in poprodajnih izdelkov ter informacij o osebnih ugodnostih, dogodkih in drugih aktualnih novic.
            </div>
          </div>
          <div>
            <div>3. Osebni podatki</div>
            <div>
            Osebni podatki ime, priimek, telefonska številka, e-poštni naslov, označeni z (*), so obvezni za obdelavo vaše zahteve iz tega spletnega obrazca; če jih ne posredujete, vaše zahteve ne moremo obdelati. Osebni podatki, obdelani za namene pošiljanja komercialnih ponudb: ime, priimek, naslov, telefonska številka, e-naslov, socio-demografski podatki (če je dovoljeno), zgodovinske informacije o nakupu (če je dovoljeno).
            </div>
          </div>
          <div>
            <div>4. Pravna podlaga</div>
            <div>
            Upravljanje vaših zahtevkov v okviru pogodbene obveznosti (obdelava vaše zahteve, navedene v tem obrazcu), zakonitega interesa (anketa o zadovoljstvu) in vaše privolitve (pošiljanje komercialnih ponudb).
            </div>
          </div>
          <div>
            <div>5. Prejemniki osebnih podatkov</div>
            <div>
            Pooblaščeni trgovci, ki po potrebi izpolnijo navedene namene, in ostale tretje osebe v skladu s svojimi pravnimi obveznostmi.
            </div>
          </div>
          <div>
            <div>6. Vaše pravice</div>
            <div>
            Svojo privolitev za obveščanje lahko kadar koli prekličete tako, da o svoji odločitvi pisno ali ustno obvestite pooblaščenega trgovca ali GA Adriatic d.o.o. na elektronski naslov: <a href="mailto:SI-Renault-AS@grandautomotive.eu">SI-Renault-AS@grandautomotive.eu</a>.
            Imate pravico do dostopa do vaših osebnih podatkov, popravka, brisanja (razen če je zahteva v nasprotju z veljavno zakonodajo) in nasprotovanja obdelavi vaših osebnih podatkov, vključno s prepovedjo pošiljanja obvestil ter druge pravice, kot je razloženo v razdelku o dodatnih informacijah (v točki 8). V primeru zlorabe vaših podatkov se lahko obrnete na GA Adriatic d.o.o. na e-naslov: <a href="mailto:SI-Renault-vop@grandautomotive.eu">SI-Renault-vop@grandautomotive.eu</a>.
            . Imate pravico do pravnega varstva. Lahko se obrnete na Informacijskega pooblaščenca RS.
            </div>
          </div>
          <div>
            <div>7. Rok za shranjevanje vaših osebnih podatkov</div>
            <div>
            Osebne podatke, navedene v tej zahtevi, GA Adriatic d.o.o. sam ali izbrani pooblaščeni trgovec uporabljata za obdelavo vaše zahteve, v času, ki je potreben za izvedbo storitve, in jih hranita največ 3 leta. V primeru uporabe vaših podatkov za anketiranje o zadovoljstvu se vaši podatki shranjujejo 1 leto po koncu te dejavnosti. V primeru vaše pozitivne privolitve se lahko vaši podatki združijo in povežejo z vašimi drugimi osebnimi podatki, ki jih hranimo. Upravljavec te osebnostne podatke zbira in obdeluje do preklica ali največ 30 let v skladu z določbami zakonodaje o varstvu osebnih podatkov.
            </div>
          </div>
          <div>
            <div>8. Dodatne informacije</div>
            <div>
              Dodatne in podrobnejše informacije o varovanju vaših osebnih podatkov najdete v naši <a href="https://www.renault.si/splosno-vop.html" target="_blank">Politiki zasebnosti</a>.
            </div>
          </div>`
        },
        hr: {
          user: {
            title: 'Vaši podaci',
            gender: {
              title: 'Status',
              select: 'Odaberite',
              error: 'Molimo da odaberete svoj status.'
            },
            naturalPerson:{
              title: 'Fizička osoba'
            },
            companyName: {
              title: 'Tvrtka',
              error: 'Molimo da unesete ime tvrtke.'
            },
            name: {
              title: 'Ime',
              error: 'Molimo da unesete svoje ime.'
            },
            lastName: {
              title: 'Prezime',
              error: 'Molimo da unesete svoje prezime.'
            },
            business: {
              title: 'Tvrtka'
            },
            email: {
              title: 'E-mail',
              error: 'Molimo da unesete svoj e-mail.'
            },
            confirmEmail: {
              title: 'Potvrdite e-mail',
              error: 'Molimo vas provjerite jeste li ispravno upisali adresu e-maila kako bismo vas lakše kontaktirali i poslali vam ponudu za kupnju vozila.'
            },
            address: {
              title: 'Ulica',
              error: 'Molimo da unesete svoju ulicu.'
            },
            houseNumber: {
              title: 'Kućni broj',
              error: 'Molimo da unesete svoj kućni broj.'
            },
            postalCode: {
              title: 'Poštanski broj',
              error: 'Molimo da unesete svoj poštanski broj.'
            },
            city: {
              title: 'Grad',
              error: 'Molimo da unesete naziv svog grada.'
            },
            phone: {
              title: 'Broj telefona',
              error: 'Molimo da unesete broj svojeg telefona.',
              placeholder: '',
              helpText: 'Zapišite telefonski broj bez razmaka.'
            },
            testDrive: 'Želim probnu vožnju.',
            additional: 'Dodatna poruka'
          },
          gdpr: {
            title: 'Potvrda',
            error: 'Označili ste DA kod najmanje jedne vrste obavijesti zato vas molimo da odaberete najmanje jedan način obavještavanja.',
            terms: {
              helpText: 'Predajom osobnih podataka dopuštam društvu GA Croatia d.o.o. ili odabranom ovlaštenom trgovcu da upotrebljavaju osobne podatke navedene u prethodnom obrascu za potrebe ponude u vremenskom razdoblju potrebnom za izvođenje usluge odnosno najdulje tri godine.',
              helpText2: 'Vođenje, nadzor i upravljanje zbirkom osobnih podataka te posredovanje podataka trećim osobama detaljno određuju Pravilnik ZOP društva GA Croatia d.o.o. i Katalog osobnih podataka, koji vodi GA Croatia d.o.o. Više o općim informacijama o zaštiti podataka možete pročitati <a href="https://www.renault.hr/opce-zop" target="_blank">OVDJE</a>. U slučaju zloupotrebe mojih podataka, mogu se obratiti društvu GA Croatia d.o.o. na e-mail adresu: <a href="mailto:HR-Renault-zop@grandautomotive.eu">HR-Renault-zop@grandautomotive.eu</a>. Imam pravo na pravnu zaštitu. Mogu kontaktirati Agenciju za zaštitu osobnih podataka.'
            },
            comply: {
              title: 'Želite da vam šaljemo poruke o aktualnim ponudama i novostima?',
              yes: 'da',
              no: 'ne',
              error: 'Molimo označite svoj odabir.',
              agree: 'Privola za informiranje'
            },
            types: {
              title: '',
              description: 'Želim primati personalizirane ponude za kupnju vozila i postprodajnih proizvoda i usluga i informacije o personaliziranim pogodnostima i događajima kao i druge aktualne vijesti od GA Croatia d.o.o., koja na hrvatskom tržištu zastupa marke Renault, Dacia, Nissan i od ovlaštenih distributera tih marki, kod kojih se nalaze moji podaci.',
              purchase: {
                title: 'Ponude za kupnju vozila',
                tooltips: 'Želim primati prilagođene ponude za kupnju vozila.',
                error: 'Molimo označite svoj odabir.'
              },
              afs: {
                title: 'Prilagođene ponude',
                tooltips: 'Želim primati ponude za kupnju vozila i postprodajnih proizvoda i usluga koje su personalizirane na temelju mojih podataka koje sam vam dostavio; i informacije o personaliziranim pogodnostima, događajima i drugim aktualnim vijestima.<br><br>Osobni podaci obrađeni u tu svrhu: ime, prezime, adresa, broj telefona, e-mail adresa, socio-demografski podaci, povijesni podaci o kupovnoj aktivnosti.',
                error: 'Molimo označite svoj odabir.'
              },
              personalization: {
                title: 'Profilirane ponude',
                tooltips: 'Dopuštam vam da prikupljate i analizirate moje osobne podatke, prošle kupovine i kupovne navike, moje interese, kako bih stvorili osobni profil, i da mi na temelju toga šaljete odgovarajuće personalizirane poruke i ponude.<br><br>Obrađeni osobni podaci u tu svrhu: ime, prezime, adresa, broj telefona, e-mail adresa, socio-demografski podaci, podaci o prošloj aktivnosti kupnje, online kolačići, podaci o geolokaciji.',
                error: 'Molimo označite svoj odabir.'
              }
            },
            channel: {
              title: '',
              description: 'Dopuštam da me GA Croatia d.o.o. i odabrani ovlašteni trgovac Renault obavještavaju na izabrane načine koje ću označiti u nastavku.',
              email: 'E-MAIL',
              sms: 'SMS',
              phone: 'TELEFON',
              mail: 'OBIČNA POŠTA',
              footer: 'Moja privola za obavješćivanje odnosi se na to pitanje i nije vezana uz ostale sustave u kojima se mogu nalaziti moji osobni podaci.<br><br>Svoju privolu mogu opozvati u svakom trenutku na način da svoju odluku pisanim ili usmenim putem pošaljem ovlaštenom trgovcu Renault ili društvu GA Croatia d.o.o. na e-mail adresu: <a href="mailto:HR-Renault-AS@grandautomotive.eu">HR-Renault-AS@grandautomotive.eu</a>.<br><br>Za podatke koje sam dostavio(la) voditelju obrade podataka mogu zahtjevati: ispravak podataka, brisanje podataka (osim kada je zahtjev u suprotnosti s važećim zakonskim propisima), uvid u podatke i izvor podataka te ograničenje upotrebe podataka. Voditelj obrade podataka moje osobne podatke prikuplja i obrađuje do opoziva ili najduže 30 godina, sukladno zakonskim odredbama o zaštiti osobnih podataka. Vođenje, nadzor i upravljanje zbirkom osobnih podataka te posredovanje podataka trećim osobama detaljno određuju Pravilnik ZOP društva GA Croatia d.o.o. i Katalog osobnih podataka, koji vodi GA Croatia d.o.o. Više o općim informacijama o zaštiti podataka možete pročitati <a href="https://www.renault.hr/opce-zop" target="_blank">OVDJE</a>. U slučaju zloupotrebe mojih podataka, mogu se obratiti društvu GA Croatia d.o.o. na e-mail adresu: <a href="mailto:HR-Renault-zop@grandautomotive.eu">HR-Renault-zop@grandautomotive.eu</a>. Imam pravo na pravnu zaštitu. Mogu kontaktirati Agenciju za zaštitu osobnih podataka.'
            }
          },
          checkboxErrors: {
            smsCheckboxError: 'Za odabrani način primanja SMS poruka obvezno je navesti broj telefona. Molimo da ispunite polje Telefon.',
            telephoneCheckboxError: 'Za odabrani način primanja poruka telefonskim putem obvezno je navesti broj telefona. Molimo da ispunite polje Telefon.​',
            postaCheckboxError: 'Za odabrani način primanja poruka običnom poštom obvezno je navesti ulicu, kućni broj, poštanski broj i grad. Molimo da ispunite polja Ulica i kućni broj, Poštanski broj i Grad.​',
            emailCheckboxError: 'Za odabrani način primanja poruka e-poštom obvezno je navesti e-mail. Molimo da ispunite polje E-mail.​',
            emailError: 'Način primanja poruka putem e-pošte je obavezan.',
            emailClickedError: 'Kod vrste obavijesti označili ste DA za primanje E-novosti, te je polje E-POŠTA obavezno.',
            allChannelsNoError: 'Ako ste kod vrste obavijesti označili NE, odabir načina komunikacije nije moguć.',
            channelsYesandTypesNoError: 'Označili ste s DA barem jednu vrstu poruka te vas zato molimo da odaberete barem jedan način primanja obavijesti.',
            noCheckboxSelectedError: 'Označili ste s DA barem jednu vrstu poruka te vas zato molimo da odaberete barem jedan način primanja obavijesti.'
          },
          legalInfoLabel: 'Upoznat(a) sam s informacijama o obradi osobnih podataka od strane GA Croatia.',
          legalInfoDisclamer: 'Obavezno pročitajte.',
          legalInfoText: `
          <div>
            <div>1. Voditelj obrade podataka</div>
            <div>
              GA Croatia d.o.o., Radnička cesta 47, 10000 Zagreb, pravna osoba koja na hrvatskom tržištu zastupa marke Renault, Dacia, Nissan i ovlašteni distributeri tih marki kod kojih se nalaze vaši podaci (popis je objavljen na <a href="https://www.renault.hr/trgovci" target="_blank">www.renault.hr/trgovci</a>) kao zajednički voditelji obrade podataka.
            </div>
          </div>
          <div>
            Za sva pitanja u vezi s obradom podataka ili vašim pravima, molimo kontaktirajte ovlaštenu osobu za zaštitu podataka: <a href="mailto:HR-Renault-zop@grandautomotive.eu">HR-Renault-zop@grandautomotive.eu</a>.
          </div>
          <div>
            <div>2. Svrhe obrade osobnih podataka</div>
            <div>
              GA Croatia d.o.o. sam ili izabrani ovlašteni distributer koristite vaše osobne podatke koje ste prenijeli putem ove internetske stranice u svrhu obrade vašeg zahtjeva iz ovog internetskog obrasca u roku potrebnom za izvršenje usluge. Vaši podaci mogu se koristiti za ankete o zadovoljstvu, za slanje komercijalnih ponuda za kupnju vozila i postprodajnih proizvoda, kao i za informacije o osobnim pogodnostima, događajima i drugim aktualnim vijestima.
            </div>
          </div>
          <div>
            <div>3. Osobni podaci</div>
            <div>
            Osobni podaci ime, prezime, broj telefona, e-mail adresu, označeni s (*), potrebni su za obradu vašeg zahtjeva iz ovog internetskog obrasca; ako ih ne navedete, ne možemo obraditi vaš zahtjev.
            Osobni podaci obrađeni u svrhu slanja komercijalnih ponuda: ime, prezime, adresa, telefonski broj, e-mail adresa, socio-demografski podaci (ako je dopušteno), povijesne informacije o kupnji (ako je dopušteno).
            </div>
          </div>
          <div>
            <div>4. Pravna osnova</div>
            <div>
              Upravljanje vašim zahtjevima prema ugovornim obvezama (obrada vašeg zahtjeva navedenog u ovom obrascu), legitimnom interesu (anketa o zadovoljstvu i vašoj privoli (slanje komercijalnih ponuda).
            </div>
          </div>
          <div>
            <div>5. Primatelji osobnih podataka</div>
            <div>
              Ovlašteni trgovci koji po potrebi ispunjavaju navedene svrhe i ostale treće strane u skladu sa svojim zakonskim obvezama.
            </div>
          </div>
          <div>
            <div>6. Vaša prava</div>
            <div>
              Vašu privolu možete opozvati u svakom trenutku na način da vašu odluku pisanim ili usmenim putem priopćite ovlaštenom distributeru ili društvu GA Croatia, d.o.o. na e-mail adresu: <a href="mailto:HR-Renault-AS@grandautomotive.eu">HR-Renault-AS@grandautomotive.eu</a>.
              Imate pravo pristupa vašim osobnim podacima, ispravljanju, brisanju (osim ako je zahtjev u suprotnosti s važećim zakonom) i pravo na prigovor obradi vaših osobnih podataka, uključujući zabranu slanja obavijesti i druga prava, kako je objašnjeno u odjeljku o dodatnim informacijama (točka 8). U slučaju zloupotrebe vaših podataka, možete kontaktirati GA Croatia d.o.o. na e-mail adresu: <a href="mailto:HR-Renault-zop@grandautomotive.eu">HR-Renault-zop@grandautomotive.eu</a>.
              Imate pravo na pravnu zaštitu. Možete kontaktirati Agenciju za zaštitu osobnih podataka (AZOP).
            </div>
          </div>
          <div>
            <div>7. Rok za pohranjivanje vaših osobnih podataka</div>
            <div>
              Osobne podatke navedene u ovom zahtjevu, GA Croatia d.o.o. sam ili odabrani ovlašteni trgovac koriste za obradu Vašeg zahtjeva u vrijeme koje je potrebno za obavljanje usluge i čuvaju ih najduže 3 godine. U slučaju korištenja vaših podataka za anketiranje o zadovoljstvu vaši podaci se čuvaju 1 godinu nakon završetka ove aktivnosti. U slučaju vaše pozitivne privole, vaši podaci mogu se objediniti i povezati s vašim drugim osobnim podacima koje čuvamo. Voditelj obrade prikuplja i obrađuje te osobne podatke do opoziva odnosno najviše 30 godina, u skladu s odredbama zakonodavstva o zaštiti osobnih podataka.
            </div>
          </div>
          <div>
            <div>8. Dodatne informacije</div>
            <div>
              Za više i detaljnije informacije o zaštiti vaših osobnih podataka, pogledajte našu <a href="https://www.renault.hr/opce-zop" target="_blank">Politiku privatnosti</a>.
            </div>
          </div>`
        },
        rs: {
          user: {
            title: 'Moji podaci',
            gender: {
              title: 'Oslovljavanje',
              select: 'Izaberite',
              error: 'Unos oslovljavanja je obavezan.'
            },
            naturalPerson:{
              title: 'Fizička osoba'
            },
            companyName: {
              title: 'Kompanija',
              error: 'Unos imena kompanije je obavezan.'
            },
            name: {
              title: 'Ime:',
              error: 'Upišite svoje ime.'
            },
            lastName: {
              title: 'Prezime:',
              error: 'Upišite svoje prezime.'
            },
            business: {
              title: 'Podjetje'
            },
            email: {
              title: 'E-adresa:',
              error: 'Upišite e-mail adresu.'
            },
            repeatEmail: {
              title: 'Potvrdite a-adresu:',
              error: 'Molimo vas da proverite da li ste ispravno upisali e-adresu kako bismo vas lakše kontaktirali i pozvali na probnu vožnju.'
            },
            address: {
              title: 'Ulica:',
              error: 'Upišite naziv ulice.'
            },
            houseNumber: {
              title: 'Kućni broj:',
              error: 'Upišite svoj kućni broj.'
            },
            postalCode: {
              title: 'Poštanski broj:',
              error: 'Upišite svoj poštanski broj.'
            },
            city: {
              title: 'Grad:',
              error: 'Upišite naziv svog grada.'
            },
            phone: {
              title: 'Broj telefona:',
              error: 'Upišite telefonski broj bez razmaka.',
              placeholder: '',
              helpText: 'Upišite telefonski broj bez razmaka.'
            }
          },
          gdpr: {
            title: 'Potvrda',
            error: 'Označili ste DA kod najmanje jedne vrste obaveštenja zato vas molimo da odaberete najmanje jedan način obaveštavanja.',
            terms: {
              helpText: 'Posredovanjem ličnih podataka dozvoljavam da KEOS, d.o.o. sam ili preko izabranog ovlašćenog distributera Renault upotrebljava lične podatke, navedene u gornjem obrascu, u svrhu izvršenja usluge u vremenskom periodu potrebnom za izvršenje usluge, odnosno najduže 3 godine.',
              helpText2: 'Vođenje, kontrola i upravljanje bazom podataka o ličnosti kao i posredovanje podataka trećim licima precizno određuje Pravilnik ZLP kompanije KEOS, d.o.o. i Katalog podataka o ličnosti, kojim upravlja KEOS, d.o.o. Više o opštim informacijama o zaštiti ličnih podataka mogu da pročitam <a href="http://www.renault.rs/opste-zlp.html" target="_blank">OVDE</a>. U slučaju zloupotrebe mojih podataka mogu da se obratim kompaniji Keos d.o.o. na e-adresu: <a href="mailto:zlp.renault@keos.rs">zlp.renault@keos.rs</a>. Imam pravo na sudsku zaštitu i obračanje Povereniku za informacije od javnog značaja i zaštitu podataka o ličnosti.'
            },
            comply: {
              title: 'Želite, da vam pošiljamo sporočila o aktualnih ponudbah in novostih?',
              yes: 'da',
              no: 'ne',
              error: 'Prosimo označite svoj izbor.',
              agree: 'SAGLASNOST ZA OBAVEŠTAVANJE'
            },
            types: {
              title: '',
              description: 'Želim da primam personalizovane ponude za kupovinu vozila i postprodajnih proizvoda i usluga kao i informacije o personalizovanim pogodnostima, događajima i druge aktuelne vesti od KEOS, d.o.o., koja na tržištu Srbije zastupa marke Renault i Dacia, uz ovlaštene distributere ovih marki kod kojih se nalaze moji podaci.',
              purchase: {
                title: 'Ponude za kupovinu vozila',
                tooltips: 'Želim da primam prilagođene prodajne ponude za kupovinu vozila.',
                error: 'Molimo označite svoj izbor.'
              },
              afs: {
                title: 'Prilagođene ponude',
                tooltips: 'Želim da dobijem ponude za kupovinu vozila i postprodajnih proizvoda i usluga koji su personalizovani na osnovu mojih podataka koje sam vam dao; i informacije o personalizovanim pogodnostima, događajima i drugim aktuelnim vestima.<br><br>Obrađeni lični podaci u tu svrhu: ime, prezime, adresa, broj telefona, e-mail adresa, socio-demografski podaci, podaci o istorijskim kupovnim aktivnostima.',
                error: 'Molimo označite svoj izbor.'
              },
              personalization: {
                title: 'Profilisane ponude',
                tooltips: 'Dopuštam vam da prikupljate i analizirate moje lične podatke, prethodne kupovine i kupovne navike, moje interese, kako bih stvorili lični profil,  na osnovu kojeg mi šaljete personalizovane poruke i ponude.<br><br>Lični podaci obrađeni u tu svrhu: ime, prezime, adresa, broj telefona, e-mail adresa, socio-demografski podaci, podaci o prošloj aktivnosti kupovine, onlajn kolačići, podaci o geolokaciji.',
                error: 'Molimo označite svoj izbor.'
              }
            },
            channel: {
              title: '',
              description: 'Dozvoljavam da me KEOS d.o.o. i izabrani ovlaščeni distributer Renault obaveštavate na izabrane načine koje ću označiti ispod.',
              email: 'E-ADRESA',
              sms: 'SMS',
              phone: 'TELEFON',
              mail: 'OBIČNA POŠTA',
              footer: 'Moja saglasnost za obaveštavanje se odnosi na ovo potraživanje i nije vezana za druge sisteme u kojima se mogu nalaziti moji lični podaci.<br><br>Svoju saglasnost mogu da otkažem u bilo kom trenutku tako što svoju odluku pisano ili usmeno saopštim ovlašćenom distributeru Renault ili kompaniji KEOS, d.o.o. na e-adresu: <a href="mailto:renault.kontakt@keos.rs">renault.kontakt@keos.rs</a>.<br><br>Za podatke, koje sam dostavio(la) rukovaocu podataka, mogu da zahtevam: ispravku podataka, brisanje podataka (osim ako je zahtev u suprotnosti sa važećim zakonodavstvom), uvid u podatke i izvor podataka kao i ograničenje upotrebe podataka. Rukovalac moje lične podatke prikuplja i obrađuje do opoziva, ili najduže 30 godina, u skladu sa odredbama zakonodavstva o zaštiti podataka o ličnosti. Vođenje, kontrola i upravljanje bazom podataka ličnosti kao i posredovanje podataka trećim licima precizno određuje Pravilnik ZLP kompanije KEOS, d.o.o. i Katalog podataka o ličnosti, kojima upravlja KEOS, d.o.o. Više o opštim informacijama o zaštiti ličnih podataka mogu da pročitam <a href="http://www.renault.rs/opste-zlp.html" target="_blank">OVDE</a>. U slučaju zloupotrebe mojih podataka mogu da se obratim kompaniji KEOS d.o.o. na e-adresu: <a href="mailto:zlp.renault@keos.rs">zlp.renault@keos.rs</a>. Imam pravo na sudsku zaštitu i obračanje Povereniku za informacije od javnog značaja i zaštitu podataka o ličnosti.'
            }
          },
          checkboxErrors: {
            smsCheckboxError: 'Za izabrani način primanja SMS poruka obavezno je navesti broj telefona. Molimo da ispunite polje Telefon.​',
            telephoneCheckboxError: 'Za izabrani način primanja poruka telefonskim putem obavezno je navesti broj telefona. Molimo da ispunite polje Telefon.​',
            postaCheckboxError: 'Za izabrani način primanja poruka običnom poštom obavezno je navesti ulicu, kućni broj, poštanski broj i grad. Molimo da ispunite polja Ulica i kućni broj, Poštanski broj i Grad.​',
            emailCheckboxError: 'Za izabrani način primanja poruka e-poštom obavezno je navesti e-adresu. Molimo da ispunite polje E-adresa.​',
            emailError: 'Primanja poruka putem e-pošte je obavezan kanal.',
            emailClickedError: 'Ako ste kod vrste obaveštavanja  označili sa DA  primanje E-novosti,  polje E-ADRESA je obavezno.',
            allChannelsNoError: 'Ako ste kod vrste obaveštavanja označili NE, izbor načina komunikacije nije moguć.',
            channelsYesandTypesNoError: 'Označili ste sa DA bar jednu vrstu poruka i vas zato molimo da izaberete bar jedan način primanja obaveštenja.',
            noCheckboxSelectedError: 'Označili ste sa DA bar jednu vrstu poruka i vas zato molimo da izaberete bar jedan način primanja obaveštenja.'
          },
          legalInfoLabel: 'Upoznat(a) sam sa informacijama o obradi ličnih podataka od strane KEOS.',
          legalInfoDisclamer: 'Obavezno pročitajte.',
          legalInfoText: `
          <div>
            <div>1. Rukovalac podataka o ličnosti</div>
            <div>
              KEOS, d.o.o., Omladinskih brigada 90 v, 11070 Beograd, pravno lice koje na tržištu Srbije zastupa marke Renault i Dacia i ovlašteni distributeri ovih marki kod kojih se nalaze vaši podaci (spisak je
              objavljen na <a href="http://www.renault.rs/trgovci" target="_blank">www.renault.rs/trgovci</a>) kao zajednički Rukovaoci podataka.
            </div>
          </div>
          <div>
            Za sva pitanja u vezi obrade podataka ili vaših prava, molimo vas da kontaktirate osobu ovlaštenu za zaštitu podataka: <a href="mailto:zlp.renault@keos.rs">zlp.renault@keos.rs</a>.
          </div>
          <div>
            <div>2. Svrhe obrade podataka o ličnosti</div>
            <div>
              KEOS, d.o.o. sam ili izabrani ovlašteni distributer koriste vaše lične podatke posredovane putem ove internet stranice u svrhu sprovođenja vašeg zahteva iz ovog obrasca, u vremenskom periodu potrebnom za
              izvršenje usluge. Vaši podaci se mogu koristiti za anketiranje o zadovoljstvu, za slanje komercijalnih ponuda za kupovinu vozila i postprodajnih proizvoda kao i informacija o personalizovanim pogodnostima, događajima i drugim
              aktuelnim vestima.
            </div>
          </div>
          <div>
            <div>3. Podaci o ličnosti</div>
            <div>
              Lični podaci ime, prezime, broj telefona, e-mail adresa označeni sa (*) su obavezni za obradu vašeg zahteva podnetog na internet sajtu; ukoliko ih ne dostavite, nismo u mogućnosti da obradimo vaš zahtev.
              Obrađeni lični podaci u svrhu slanja komercijalnih ponuda: ime, prezime, adresa, broj telefona, e-mail adresa, socio-demografski podaci (ako je dozvoljeno), podaci o istorijskim kupovnim aktivnostima (ako je dozvoljeno).
            </div>
          </div>
          <div>
            <div>4. Pravna osnova</div>
            <div>
              Upravljanje vašim zahtevima u kontekstu ugovornih mera (obrada vašeg zahteva navedenog u ovom obrascu), legitimnog interesa (anketiranje o zadovoljstvu) i vaše saglasnosti (slanje komercijalnih ponuda).
            </div>
          </div>
          <div>
            <div>5. Primatelji ličnih podataka</div>
            <div>
              Ovlašteni distributeri, koji po potrebi ispunjavaju navedene svrhe i ostale treće strane u skladu sa svojim zakonskim obavezama.
            </div>
          </div>
          <div>
            <div>6. Vaša prava</div>
            <div>
              Svoju saglasnost za primanje poruka možete da otkažete u bilo kom trenutku tako što svoju odluku pisano ili usmeno saopštite ovlaštenom distributeru ili kompaniji KEOS, d.o.o. na e-adresu: <a href="mailto:renault.kontakt@keos.rs">renault.kontakt@keos.rs</a>.
              Imate pravo pristupa vašim ličnim podacima, ispravljanje, brisanje (osim ako je zahtev u suprotnosti sa važećim zakonodavstvom) i protivljenje obradi vaših ličnih podataka, uključujući zabranu slanja obaveštenja i druga prava,
              kao što je objašnjeno u odeljku Dodatne informacije (u tački 8). U slučaju zloupotrebe vaših podataka možete da se obratite kompaniji Keos d.o.o. na e-adresu: <a href="mailto:zlp.renault@keos.rs">zlp.renault@keos.rs</a>.
              Imate pravo na sudsku zaštitu i obraćanje Povereniku za informacije od javnog značaja i zaštitu podataka o ličnosti.
            </div>
          </div>
          <div>
            <div>7. Rok čuvanja vaših ličnih podataka</div>
            <div>
              Lične podatke navedene u ovom zahtevu, KEOS, d.o.o. sam ili izabrani ovlašteni distributer upotrebljavaju u svrhu obrade vašeg zahteva u vremenskom periodu potrebnom za izvršenje usluge, i čuvaju ih najduže 3
              godine. U slučaju korišćenja vaših podataka za anketiranje o zadovoljstvu vaši podaci se čuvaju 1 godinu nakon završetka ove aktivnosti. U slučaju vaše pozitivne saglasnosti vaši podaci se mogu objediniti i povezati s vašim
              drugim ličnim podacima koje čuvamo. Rukovalac te podatke o ličnosti prikuplja i obrađuje do opoziva, ili najduže 30 godina, u skladu sa odredbama zakonodavstva o zaštiti podataka o ličnosti.
            </div>
          </div>
          <div>
            <div>8. Dodatne informacije</div>
            <div>
              Dodatne i detaljne informacije o zaštiti ličnih podataka možete pronaći u našoj <a href="http://www.renault.rs/opste-zlp.html" target="_blank">Politici privatnosti</a>.
            </div>
          </div>`
        }
      },

      dacia: {
        si: {

          user: {
            title: 'Moji podatki',
            gender: {
              title: 'Naziv',
              select: 'Izberite',
              error: 'Izberite svoj naziv.'
            },
            naturalPerson:{
              title: 'Fizična oseba'
            },
            companyName: {
              title: 'Podjetje',
              error: 'Vpišite ime podjetja.'
            },
            name: {
              title: 'Ime',
              error: 'Vpišite svoje ime.'
            },
            lastName: {
              title: 'Priimek',
              error: 'Vpišite svoj priimek.'
            },
            business: {
              title: 'Podjetje'
            },
            email: {
              title: 'E-pošta',
              error: 'Vpišite svoj e-mail naslov.'
            },
            confirmEmail: {
              title: 'Potrdite e-pošto',
              error: 'Prosimo, preverite, da ste pravilno vpisali naslov e-pošte, da vas bomo lahko kontaktirali in povabili na testno vožnjo.'
            },
            address: {
              title: 'Ulica',
              error: 'Vpišite ulico, kjer prebivate.'
            },
            houseNumber: {
              title: 'Hišna številka',
              error: 'Vpišite hišno številko.'
            },
            postalCode: {
              title: 'Poštna številka',
              error: 'Vpišite poštno številko kraja, kjer prebivate.'
            },
            city: {
              title: 'Kraj',
              error: 'Vpišite kraj, kjer prebivate.'
            },
            phone: {
              title: 'Številka telefona',
              error: 'Zapišite številko telefona brez presledkov ali ločil.',
              placeholder: '',
              helpText: 'Zapišite številko telefona brez presledkov ali ločil.',
              helpText090: 'Telefonska številka ni veljavna.'
            }
          },
          gdpr: {
            title: 'Potrditev',
            error: 'Označili ste DA pri najmanj eni vrsti sporočil, zato prosimo izberite najmanj en način obveščanja.',
            terms: {
              title: 'Prebral/a sem in se strinjam s pravnim obvestilom',
              error: 'Strinjati se morate s pravnim obvestilom.',
              helpText: 'S posredovanjem osebnih podatkov dovoljujem, da GA Adriatic d.o.o., sam ali izbrani pooblaščeni trgovec uporabljata osebne podatke, navedene v zgornjem obrazcu, za namene izvedbe testne vožnje v časovnem obdobju, potrebnem za izvedbo storitve, oz. najdlje 3 leta.',
              helpText2: 'Vodenje, nadzor in upravljanje zbirke osebnih podatkov ter posredovanje podatkov tretjim natančno določata Pravilnik VOP podjetja GA Adriatic d.o.o. in Katalog osebnih podatkov, ki jih upravlja GA Adriatic d.o.o. Več o splošnih informacijah o varstvu podatkov si lahko preberem <a href="https://www.renault.si/splosno-vop" target="_blank">TUKAJ</a>. V primeru zlorabe mojih podatkov se lahko obrnem na GA Adriatic d.o.o. na e-naslov: <a href="mailto:vop.renault@renault.si">vop.renault@renault.si</a>'
            },
            comply: {
              title: 'Želite, da vam pošiljamo sporočila o aktualnih ponudbah in novostih?',
              yes: 'da',
              no: 'ne',
              error: 'Prosimo označite svoj izbor.',
              agree: 'Privolitev za obveščanje'
            },
            types: {
              title: '',
              description: 'Želim prejemati osebno prilagojene ponudbe za nakup vozil in poprodajnih izdelkov in storitev ter informacije o prilagojenih ugodnostih in dogodkih in druge aktualne novice od GA Adriatic d.o.o., ki na slovenskem trgu zastopa znamke Renault, Dacia, Nissan, Alpine in od pooblaščenih trgovcev teh znamk, pri katerih se nahajajo moji podatki.',
              purchase: {
                title: 'Ponudbe za nakup vozila',
                tooltips: 'Želim prejemati prilagojene prodajne ponudbe za nakup vozil.',
                error: 'Prosimo označite svoj izbor.'
              },
              afs: {
                title: 'Ponudbe za nakup poprodajnih izdelkov in storitev',
                tooltips: 'Želim prejemati prilagojene prodajne ponudbe za nakup nadomestnih delov, originalne dodatne opreme, servisnih storitev, pogodb o vzdrževanju, podaljšanem jamstvu in podobno.',
                error: 'Prosimo označite svoj izbor.'
              },
              personalization: {
                title: 'Personalizacija in profiliranje',
                tooltips: 'Dovolim, da me kontaktirate z namenom pridobivanja dodatnih osebnih podatkov (na primer spol, starost, letno prevožene razdalje, geografski podatki, izobrazba, poklic, število družinskih članov v gospodinjstvu, število otrok, prostočasne dejavnosti, uporaba interneta, pretekla lastništva vozil, razlogi za nakup vozila ter drugi podobni podatki), ki jih boste uporabili, da mi boste posredovali personalizirane ponudbe, ki ustrezajo mojim željam in potrebam. Sam(a) odločam, katere podatke vam bom zaupal(a) in v vsakem trenutku lahko zahtevam izbris teh podatkov.',
                error: 'Prosimo označite svoj izbor.'
              }
            },
            channel: {
              title: 'Način prejemanja sporočil',
              description: 'Dovoljujem, da me GA Adriatic d.o.o. sam ali izbrani pooblaščeni trgovec Renault obveščata na izbrane načine, ki jih bom označil(a) spodaj.',
              email: 'E-pošta',
              sms: 'SMS',
              phone: 'Telefon',
              mail: 'Običajna pošta',
              footer: 'Moja privolitev za obveščanje se nanaša na to povpraševanje in ni vezana na ostale sisteme, kjer se lahko nahajajo moji osebni podatki.<br><br>Svojo privolitev lahko kadarkoli prekličem tako, da svojo odločitev pisno ali ustno sporočim pooblaščenemu trgovcu Renault ali podjetju GA Adriatic d.o.o. na e-naslov: <a href="mailto:pisite.nam@renault.si">pisite.nam@renault.si</a>.<br><br>Za podatke, ki sem jih posredoval(a) upravljalcu podatkov, lahko zahtevam: popravek podatkov, izbris podatkov (razen, če je zahteva v nasprotju z veljavno zakonodajo), vpogled v podatke in izvor podatkov ter omejitev uporabe podatkov. Upravljavec moje osebne podatke zbira in obdeluje do preklica, oz. največ 30 let, skladno z določbami zakonodaje o varovanju osebnih podatkov. Vodenje, nadzor in upravljanje zbirke osebnih podatkov ter posredovanje podatkov tretjim natančno določata Pravilnik VOP podjetja GA Adriatic d.o.o. in Katalog osebnih podatkov, ki jih upravlja GA Adriatic d.o.o.. Več o splošnih informacijah o varstvu podatkov si lahko preberem <a href="https://www.renault.si/splosno-vop" target="_blank">TUKAJ</a>. V primeru zlorabe mojih podatkov se lahko obrnem na GA Adriatic d.o.o. na e-naslov: <a href="mailto:vop.renault@renault.si">vop.renault@renault.si</a>.'
            }
          },
          checkboxErrors: {
            smsCheckboxError: 'Za izbrani način prejemanja sporočil SMS je obvezen vnos telefonske številke. Prosimo, izpolnite polje Telefon zgoraj.',
            telephoneCheckboxError: 'Za izbrani način prejemanja sporočil TELEFON je obvezen vnos telefonske številke. Prosimo, izpolnite polje Telefon zgoraj.',
            postaCheckboxError: 'Za izbrani način prejemanja sporočil OBIČAJNA POŠTA je obvezen vnos naslova. Prosimo, izpolnite polja Ulica in hišna številka, Poštna številka in Pošta zgoraj.',
            emailCheckboxError: 'Za izbrani način prejemanja sporočil E-POŠTA je obvezen vnos naslova e-pošte. Prosimo, izpolnite polje E-pošta zgoraj.',
            emailError: 'Način prejemanja sporočil preko E-pošte je obvezen.',
            emailClickedError: 'Označili ste DA pri vrsti sporočila E-novice, zato je polje E-POŠTA obvezno.',
            allChannelsNoError: 'Ker ste pri vseh vrstah sporočil označili NE, izbira načina obveščanja ni mogoča.',
            channelsYesandTypesNoError: 'Označili ste DA pri najmanj eni vrsti sporočil, zato prosimo izberite najmanj en način obveščanja.',
            noCheckboxSelectedError: 'Označili ste DA pri najmanj eni vrsti sporočil, zato prosimo izberite najmanj en način obveščanja.'
          },
          legalInfoLabel: 'Seznanjen(a) sem z informacijami o obdelavi osebnih podatkov GA Adriatic d.o.o.',
          legalInfoDisclamer: 'Prosimo, obvezno preberite.',
          legalInfoText: `
            <div>
              <div>1. Upravljavec osebnih podatkov</div>
              <div>
              GA Adriatic d.o.o., Dunajska 22, 1000 Ljubljana, pravna oseba, ki na slovenskem trgu zastopa znamke Renault, Dacia, Nissan in Alpine ter pooblaščeni trgovci znamk, kjer se nahajajo vaši podatki (seznam je objavljen na <a href="https://www.dacia.si/trgovci" target="_blank">www.dacia.si/trgovci</a>) kot skupni upravljavci podatkov.
              </div>
            </div>
            <div>
            Za vsa vprašanja glede obdelave podatkov ali vaših pravic se obrnite na pooblaščeno osebo za zaščito podatkov: <a href="mailto:SI-Dacia-vop@grandautomotive.eu">SI-Dacia-vop@grandautomotive.eu</a>.
            </div>
            <div>
              <div>2. Namen obdelave osebnih podatkov</div>
              <div>
              GA Adriatic d.o.o. sam ali izbrani pooblaščeni trgovec uporabljata vaše osebne podatke, ki ste jih posredovali prek te spletne strani, za namen obdelave vaše zahteve iz tega obrazca, v roku, ki je potreben za izvedbo storitve. Vaši podatki se lahko uporabijo za anketiranje o zadovoljstvu, za pošiljanje komercialnih ponudb za nakup vozil in poprodajnih izdelkov ter informacij o osebnih ugodnostih, dogodkih in drugih aktualnih novic.
              </div>
            </div>
            <div>
              <div>3. Osebni podatki</div>
              <div>
              Osebni podatki ime, priimek, telefonska številka, e-poštni naslov, označeni z (*), so obvezni za obdelavo vaše zahteve iz tega spletnega obrazca; če jih ne posredujete, vaše zahteve ne moremo obdelati. Osebni podatki, obdelani za namene pošiljanja komercialnih ponudb: ime, priimek, naslov, telefonska številka, e-naslov, socio-demografski podatki (če je dovoljeno), zgodovinske informacije o nakupu (če je dovoljeno).
              </div>
            </div>
            <div>
              <div>4. Pravna podlaga</div>
              <div>
              Upravljanje vaših zahtevkov v okviru pogodbene obveznosti (obdelava vaše zahteve, navedene v tem obrazcu), zakonitega interesa (anketa o zadovoljstvu) in vaše privolitve (pošiljanje komercialnih ponudb).
              </div>
            </div>
            <div>
              <div>5. Prejemniki osebnih podatkov</div>
              <div>
              Pooblaščeni trgovci, ki po potrebi izpolnijo navedene namene, in ostale tretje osebe v skladu s svojimi pravnimi obveznostmi.
              </div>
            </div>
            <div>
              <div>6. Vaše pravice</div>
              <div>
              Svojo privolitev za obveščanje lahko kadar koli prekličete tako, da o svoji odločitvi pisno ali ustno obvestite pooblaščenega trgovca ali GA Adriatic d.o.o. na elektronski naslov: <a href="mailto:SI-Dacia-AS@grandautomotive.eu">SI-Dacia-AS@grandautomotive.eu</a>.
              . Imate pravico do dostopa do vaših osebnih podatkov, popravka, brisanja (razen če je zahteva v nasprotju z veljavno zakonodajo) in nasprotovanja obdelavi vaših osebnih podatkov, vključno s prepovedjo pošiljanja obvestil ter druge pravice, kot je razloženo v razdelku o dodatnih informacijah (v točki 8). V primeru zlorabe vaših podatkov se lahko obrnete na GA Adriatic d.o.o. na e-naslov: <a href="mailto:SI-Dacia-vop@grandautomotive.eu">SI-Dacia-vop@grandautomotive.eu</a>.
              . Imate pravico do pravnega varstva. Lahko se obrnete na Informacijskega pooblaščenca RS.
              </div>
            </div>
            <div>
              <div>7. Rok za shranjevanje vaših osebnih podatkov</div>
              <div>
              Osebne podatke, navedene v tej zahtevi, GA Adriatic d.o.o. sam ali izbrani pooblaščeni trgovec uporabljata za obdelavo vaše zahteve, v času, ki je potreben za izvedbo storitve, in jih hranita največ 3 leta. V primeru uporabe vaših podatkov anketiranje o zadovoljstvu se vaši podatki shranjujejo 1 leto po koncu te dejavnosti. V primeru vaše pozitivne privolitve se lahko vaši podatki združijo in povežejo z vašimi drugimi osebnimi podatki, ki jih hranimo. Upravljavec te osebnostne podatke zbira in obdeluje do preklica ali največ 30 let v skladu z določbami zakonodaje o varstvu osebnih podatkov.
              </div>
            </div>
            <div>
              <div>8. Dodatne informacije</div>
              <div>
                Dodatne in podrobnejše informacije o varovanju vaših osebnih podatkov najdete v naši <a href="https://www.dacia.si/splosno-vop.html" target="_blank">Politiki zasebnosti</a>.
              </div>
            </div>`
        },
        hr: {
          user: {
            title: 'Vaši podaci',
            gender: {
              title: 'Status',
              select: 'Odaberite',
              error: 'Molimo da odaberete svoj status.'
            },
            naturalPerson:{
              title: 'Fizička osoba'
            },
            companyName: {
              title: 'Tvrtka',
              error: 'Molimo da unesete ime tvrtke.'
            },
            name: {
              title: 'Ime',
              error: 'Molimo da unesete svoje ime.'
            },
            lastName: {
              title: 'Prezime',
              error: 'Molimo da unesete svoje prezime.'
            },
            business: {
              title: 'Tvrtka'
            },
            email: {
              title: 'E-mail',
              error: 'Molimo da unesete svoj e-mail.'
            },
            confirmEmail: {
              title: 'Potvrdite e-mail',
              error: 'Molimo vas provjerite jeste li ispravno upisali adresu e-maila kako bismo vas lakše kontaktirali i poslali vam ponudu za kupnju vozila.'
            },
            address: {
              title: 'Ulica',
              error: 'Molimo da unesete svoju ulicu.'
            },
            houseNumber: {
              title: 'Kućni broj',
              error: 'Molimo da unesete svoj kućni broj.'
            },
            postalCode: {
              title: 'Poštanski broj',
              error: 'Molimo da unesete svoj poštanski broj.'
            },
            city: {
              title: 'Grad',
              error: 'Molimo da unesete naziv svog grada.'
            },
            phone: {
              title: 'Broj telefona',
              error: 'Molimo da unesete broj svojeg telefona.',
              placeholder: '',
              helpText: 'Zapišite telefonski broj bez razmaka.'
            },
            testDrive: 'Želim probnu vožnju.',
            additional: 'Dodatna poruka'
          },
          gdpr: {
            title: 'Potvrda',
            error: 'Označili ste DA kod najmanje jedne vrste obavijesti zato vas molimo da odaberete najmanje jedan način obavještavanja.',
            terms: {
              helpText: 'Predajom osobnih podataka dopuštam društvu GA Croatia d.o.o. ili odabranom ovlaštenom trgovcu da upotrebljavaju osobne podatke navedene u prethodnom obrascu za potrebe ponude u vremenskom razdoblju potrebnom za izvođenje usluge odnosno najdulje tri godine.',
              helpText2: 'Vođenje, nadzor i upravljanje zbirkom osobnih podataka te posredovanje podataka trećim osobama detaljno određuju Pravilnik ZOP društva GA Croatia d.o.o. i Katalog osobnih podataka, koji vodi GA Croatia d.o.o. Više o općim informacijama o zaštiti podataka možete pročitati <a href="https://www.renault.hr/opce-zop" target="_blank">OVDJE</a>. U slučaju zloupotrebe mojih podataka, mogu se obratiti društvu GA Croatia d.o.o. na e-mail adresu: <a href="mailto:HR-Renault-zop@grandautomotive.eu">HR-Renault-zop@grandautomotive.eu</a>. Imam pravo na pravnu zaštitu. Mogu kontaktirati Agenciju za zaštitu osobnih podataka.'
            },
            comply: {
              title: 'Želite da vam šaljemo poruke o aktualnim ponudama i novostima?',
              yes: 'da',
              no: 'ne',
              error: 'Molimo označite svoj odabir.',
              agree: 'Privola za informiranje'
            },
            types: {
              title: '',
              description: 'Želim primati personalizirane ponude za kupnju vozila i postprodajnih proizvoda i usluga i informacije o personaliziranim pogodnostima i događajima kao i druge aktualne vijesti od GA Croatia d.o.o., koja na hrvatskom tržištu zastupa marke Renault, Dacia, Nissan i od ovlaštenih distributera tih marki, kod kojih se nalaze moji podaci.',
              purchase: {
                title: 'Ponude za kupnju vozila',
                tooltips: 'Želim primati prilagođene ponude za kupnju vozila.',
                error: 'Molimo označite svoj odabir.'
              },
              afs: {
                title: 'Prilagođene ponude',
                tooltips: 'Želim primati ponude za kupnju vozila i postprodajnih proizvoda i usluga koje su personalizirane na temelju mojih podataka koje sam vam dostavio; i informacije o personaliziranim pogodnostima, događajima i drugim aktualnim vijestima.<br><br>Osobni podaci obrađeni u tu svrhu: ime, prezime, adresa, broj telefona, e-mail adresa, socio-demografski podaci, povijesni podaci o kupovnoj aktivnosti.',
                error: 'Molimo označite svoj odabir.'
              },
              personalization: {
                title: 'Profilirane ponude',
                tooltips: 'Dopuštam vam da prikupljate i analizirate moje osobne podatke, prošle kupovine i kupovne navike, moje interese, kako bih stvorili osobni profil, i da mi na temelju toga šaljete odgovarajuće personalizirane poruke i ponude.<br><br>Obrađeni osobni podaci u tu svrhu: ime, prezime, adresa, broj telefona, e-mail adresa, socio-demografski podaci, podaci o prošloj aktivnosti kupnje, online kolačići, podaci o geolokaciji.',
                error: 'Molimo označite svoj odabir.'
              }
            },
            channel: {
              title: '',
              description: 'Dopuštam da me GA Croatia d.o.o. i odabrani ovlašteni trgovac Renault obavještavaju na izabrane načine koje ću označiti u nastavku.',
              email: 'E-MAIL',
              sms: 'SMS',
              phone: 'TELEFON',
              mail: 'OBIČNA POŠTA',
              footer: 'Moja privola za obavješćivanje odnosi se na to pitanje i nije vezana uz ostale sustave u kojima se mogu nalaziti moji osobni podaci.<br><br>Svoju privolu mogu opozvati u svakom trenutku na način da svoju odluku pisanim ili usmenim putem pošaljem ovlaštenom trgovcu Renault ili društvu GA Croatia d.o.o. na e-mail adresu: <a href="mailto:HR-Renault-AS@grandautomotive.eu">HR-Renault-AS@grandautomotive.eu</a>.<br><br>Za podatke koje sam dostavio(la) voditelju obrade podataka mogu zahtjevati: ispravak podataka, brisanje podataka (osim kada je zahtjev u suprotnosti s važećim zakonskim propisima), uvid u podatke i izvor podataka te ograničenje upotrebe podataka. Voditelj obrade podataka moje osobne podatke prikuplja i obrađuje do opoziva ili najduže 30 godina, sukladno zakonskim odredbama o zaštiti osobnih podataka. Vođenje, nadzor i upravljanje zbirkom osobnih podataka te posredovanje podataka trećim osobama detaljno određuju Pravilnik ZOP društva GA Croatia d.o.o. i Katalog osobnih podataka, koji vodi GA Croatia d.o.o. Više o općim informacijama o zaštiti podataka možete pročitati <a href="https://www.renault.hr/opce-zop" target="_blank">OVDJE</a>. U slučaju zloupotrebe mojih podataka, mogu se obratiti društvu GA Croatia d.o.o. na e-mail adresu: <a href="mailto:HR-Renault-zop@grandautomotive.eu">HR-Renault-zop@grandautomotive.eu</a>. Imam pravo na pravnu zaštitu. Mogu kontaktirati Agenciju za zaštitu osobnih podataka.'
            }
          },
          checkboxErrors: {
            smsCheckboxError: 'Za odabrani način primanja SMS poruka obvezno je navesti broj telefona. Molimo da ispunite polje Telefon.',
            telephoneCheckboxError: 'Za odabrani način primanja poruka telefonskim putem obvezno je navesti broj telefona. Molimo da ispunite polje Telefon.​',
            postaCheckboxError: 'Za odabrani način primanja poruka običnom poštom obvezno je navesti ulicu, kućni broj, poštanski broj i grad. Molimo da ispunite polja Ulica i kućni broj, Poštanski broj i Grad.​',
            emailCheckboxError: 'Za odabrani način primanja poruka e-poštom obvezno je navesti e-mail. Molimo da ispunite polje E-mail.​',
            emailError: 'Način primanja poruka putem e-pošte je obavezan.',
            emailClickedError: 'Kod vrste obavijesti označili ste DA za primanje E-novosti, te je polje E-POŠTA obavezno.',
            allChannelsNoError: 'Ako ste kod vrste obavijesti označili NE, odabir načina komunikacije nije moguć.',
            channelsYesandTypesNoError: 'Označili ste s DA barem jednu vrstu poruka te vas zato molimo da odaberete barem jedan način primanja obavijesti.',
            noCheckboxSelectedError: 'Označili ste s DA barem jednu vrstu poruka te vas zato molimo da odaberete barem jedan način primanja obavijesti.'
          },
          legalInfoLabel: 'Upoznat(a) sam s informacijama o obradi osobnih podataka od strane GA Croatia.',
          legalInfoDisclamer: 'Obavezno pročitajte.',
          legalInfoText: `
            <div>
              <div>1. Voditelj obrade podataka</div>
              <div>
                GA Croatia d.o.o., Radnička cesta 47, 10000 Zagreb, pravna osoba koja na hrvatskom tržištu zastupa marke Renault, Dacia, Nissan i ovlašteni distributeri tih marki kod kojih se nalaze vaši podaci (popis je objavljen na <a href="https://www.dacia.hr/trgovci" target="_blank">www.dacia.hr/trgovci</a>) kao zajednički voditelji obrade podataka.
              </div>
            </div>
            <div>
              Za sva pitanja u vezi s obradom podataka ili vašim pravima, molimo kontaktirajte ovlaštenu osobu za zaštitu podataka: <a href="mailto:HR-Dacia-zop@grandautomotive.eu">HR-Dacia-zop@grandautomotive.eu</a>.
            </div>
            <div>
              <div>2. Svrhe obrade osobnih podataka</div>
              <div>
                GA Croatia d.o.o. sam ili izabrani ovlašteni distributer koristite vaše osobne podatke koje ste prenijeli putem ove internetske stranice u svrhu obrade vašeg zahtjeva iz ovog internetskog obrasca u roku potrebnom za izvršenje usluge. Vaši podaci mogu se koristiti za ankete o zadovoljstvu, za slanje komercijalnih ponuda za kupnju vozila i postprodajnih proizvoda, kao i za informacije o osobnim pogodnostima, događajima i drugim aktualnim vijestima.
              </div>
            </div>
            <div>
              <div>3. Osobni podaci</div>
              <div>
              Osobni podaci ime, prezime, broj telefona, e-mail adresu, označeni s (*), potrebni su za obradu vašeg zahtjeva iz ovog internetskog obrasca; ako ih ne navedete, ne možemo obraditi vaš zahtjev.
              Osobni podaci obrađeni u svrhu slanja komercijalnih ponuda: ime, prezime, adresa, telefonski broj, e-mail adresa, socio-demografski podaci (ako je dopušteno), povijesne informacije o kupnji (ako je dopušteno).
              </div>
            </div>
            <div>
              <div>4. Pravna osnova</div>
              <div>
                Upravljanje vašim zahtjevima prema ugovornim obvezama (obrada vašeg zahtjeva navedenog u ovom obrascu), legitimnom interesu (anketa o zadovoljstvu i vašoj privoli (slanje komercijalnih ponuda).
              </div>
            </div>
            <div>
              <div>5. Primatelji osobnih podataka</div>
              <div>
                Ovlašteni trgovci koji po potrebi ispunjavaju navedene svrhe i ostale treće strane u skladu sa svojim zakonskim obvezama.
              </div>
            </div>
            <div>
              <div>6. Vaša prava</div>
              <div>
                Vašu privolu možete opozvati u svakom trenutku na način da vašu odluku pisanim ili usmenim putem priopćite ovlaštenom distributeru ili društvu GA Croatia, d.o.o. na e-mail adresu: <a href="mailto:HR-Renault-AS@grandautomotive.eu">HR-Renault-AS@grandautomotive.eu</a>.
                Imate pravo pristupa vašim osobnim podacima, ispravljanju, brisanju (osim ako je zahtjev u suprotnosti s važećim zakonom) i pravo na prigovor obradi vaših osobnih podataka, uključujući zabranu slanja obavijesti i druga prava, kako je objašnjeno u odjeljku o dodatnim informacijama (točka 8). U slučaju zloupotrebe vaših podataka, možete kontaktirati GA Croatia d.o.o. na e-mail adresu: <a href="mailto:HR-Dacia-zop@grandautomotive.eu">HR-Dacia-zop@grandautomotive.eu</a>.
                Imate pravo na pravnu zaštitu. Možete kontaktirati Agenciju za zaštitu osobnih podataka (AZOP).
              </div>
            </div>
            <div>
              <div>7. Rok za pohranjivanje vaših osobnih podataka</div>
              <div>
                Osobne podatke navedene u ovom zahtjevu, GA Croatia d.o.o. sam ili odabrani ovlašteni trgovac koriste za obradu Vašeg zahtjeva u vrijeme koje je potrebno za obavljanje usluge i čuvaju ih najduže 3 godine. U slučaju korištenja vaših podataka za anketiranje o zadovoljstvu vaši podaci se čuvaju 1 godinu nakon završetka ove aktivnosti. U slučaju vaše pozitivne privole, vaši podaci mogu se objediniti i povezati s vašim drugim osobnim podacima koje čuvamo. Voditelj obrade prikuplja i obrađuje te osobne podatke do opoziva odnosno najviše 30 godina, u skladu s odredbama zakonodavstva o zaštiti osobnih podataka.
              </div>
            </div>
            <div>
              <div>8. Dodatne informacije</div>
              <div>
                Za više i detaljnije informacije o zaštiti vaših osobnih podataka, pogledajte našu <a href="https://www.dacia.hr/opce-zop" target="_blank">Politiku privatnosti</a>.
              </div>
            </div>`
        },
        rs: {

        }
      },

      nissan: {
        si: {
          user: {
            title: 'Moji podatki',
            gender: {
              title: 'Naziv',
              select: 'Izberite',
              error: 'Izberite svoj naziv.'
            },
            naturalPerson:{
              title: 'Fizična oseba'
            },
            companyName: {
              title: 'Podjetje',
              error: 'Vpišite ime podjetja.'
            },
            name: {
              title: 'Ime',
              error: 'Vpišite svoje ime.'
            },
            lastName: {
              title: 'Priimek',
              error: 'Vpišite svoj priimek.'
            },
            business: {
              title: 'Podjetje'
            },
            email: {
              title: 'E-pošta',
              error: 'Vpišite svoj e-mail naslov.'
            },
            confirmEmail: {
              title: 'Potrdite e-pošto',
              error: 'Prosimo, preverite, da ste pravilno vpisali naslov e-pošte, da vas bomo lahko kontaktirali in povabili na testno vožnjo.'
            },
            address: {
              title: 'Ulica',
              error: 'Vpišite ulico, kjer prebivate.'
            },
            houseNumber: {
              title: 'Hišna številka',
              error: 'Vpišite hišno številko.'
            },
            postalCode: {
              title: 'Poštna številka',
              error: 'Vpišite poštno številko kraja, kjer prebivate.'
            },
            city: {
              title: 'Kraj',
              error: 'Vpišite kraj, kjer prebivate.'
            },
            phone: {
              title: 'Številka telefona',
              error: 'Zapišite številko telefona brez presledkov ali ločil.',
              placeholder: '',
              helpText: 'Zapišite številko telefona brez presledkov ali ločil.'
            }
          },
          gdpr: {
            title: 'Potrditev',
            error: 'Označili ste DA pri najmanj eni vrsti sporočil, zato prosimo izberite najmanj en način obveščanja.',
            terms: {
              title: 'Prebral/a sem in se strinjam s pravnim obvestilom',
              error: 'Strinjati se morate s pravnim obvestilom.',
              helpText: 'S posredovanjem osebnih podatkov dovoljujem, da GA Adriatic d.o.o., sam ali izbrani pooblaščeni trgovec uporabljata osebne podatke, navedene v zgornjem obrazcu, za namene izvedbe testne vožnje v časovnem obdobju, potrebnem za izvedbo storitve, oz. najdlje 3 leta.',
              helpText2: 'Vodenje, nadzor in upravljanje zbirke osebnih podatkov ter posredovanje podatkov tretjim natančno določata Pravilnik VOP podjetja GA Adriatic d.o.o. in Katalog osebnih podatkov, ki jih upravlja GA Adriatic d.o.o. Več o splošnih informacijah o varstvu podatkov si lahko preberem <a href="https://www.renault.si/splosno-vop" target="_blank">TUKAJ</a>. V primeru zlorabe mojih podatkov se lahko obrnem na GA Adriatic d.o.o. na e-naslov: <a href="mailto:vop.renault@renault.si">vop.renault@renault.si</a>'
            },
            comply: {
              title: 'Želite, da vam pošiljamo sporočila o aktualnih ponudbah in novostih?',
              yes: 'da',
              no: 'ne',
              error: 'Prosimo označite svoj izbor.',
              agree: 'Privolitev za obveščanje'
            },
            types: {
              title: '',
              description: 'Želim prejemati osebno prilagojene ponudbe za nakup vozil in poprodajnih izdelkov in storitev ter informacije o prilagojenih ugodnostih in dogodkih in druge aktualne novice od GA Adriatic d.o.o., ki na slovenskem trgu zastopa znamke Renault, Dacia, Nissan, Alpine in od pooblaščenih trgovcev teh znamk, pri katerih se nahajajo moji podatki.',
              purchase: {
                title: 'Ponudbe za nakup vozila',
                tooltips: 'Želim prejemati prilagojene prodajne ponudbe za nakup vozil.',
                error: 'Prosimo označite svoj izbor.'
              },
              afs: {
                title: 'Ponudbe za nakup poprodajnih izdelkov in storitev',
                tooltips: 'Želim prejemati prilagojene prodajne ponudbe za nakup nadomestnih delov, originalne dodatne opreme, servisnih storitev, pogodb o vzdrževanju, podaljšanem jamstvu in podobno.',
                error: 'Prosimo označite svoj izbor.'
              },
              personalization: {
                title: 'Personalizacija in profiliranje',
                tooltips: 'Dovolim, da me kontaktirate z namenom pridobivanja dodatnih osebnih podatkov (na primer spol, starost, letno prevožene razdalje, geografski podatki, izobrazba, poklic, število družinskih članov v gospodinjstvu, število otrok, prostočasne dejavnosti, uporaba interneta, pretekla lastništva vozil, razlogi za nakup vozila ter drugi podobni podatki), ki jih boste uporabili, da mi boste posredovali personalizirane ponudbe, ki ustrezajo mojim željam in potrebam. Sam(a) odločam, katere podatke vam bom zaupal(a) in v vsakem trenutku lahko zahtevam izbris teh podatkov.',
                error: 'Prosimo označite svoj izbor.'
              }
            },
            channel: {
              title: 'Način prejemanja sporočil',
              description: 'Dovoljujem, da me GA Adriatic d.o.o. sam ali izbrani pooblaščeni trgovec Renault obveščata na izbrane načine, ki jih bom označil(a) spodaj.',
              email: 'E-pošta',
              sms: 'SMS',
              phone: 'Telefon',
              mail: 'Običajna pošta',
              footer: 'Moja privolitev za obveščanje se nanaša na to povpraševanje in ni vezana na ostale sisteme, kjer se lahko nahajajo moji osebni podatki.<br><br>Svojo privolitev lahko kadarkoli prekličem tako, da svojo odločitev pisno ali ustno sporočim pooblaščenemu trgovcu Renault ali podjetju GA Adriatic d.o.o. na e-naslov: <a href="mailto:pisite.nam@renault.si">pisite.nam@renault.si</a>.<br><br>Za podatke, ki sem jih posredoval(a) upravljalcu podatkov, lahko zahtevam: popravek podatkov, izbris podatkov (razen, če je zahteva v nasprotju z veljavno zakonodajo), vpogled v podatke in izvor podatkov ter omejitev uporabe podatkov. Upravljavec moje osebne podatke zbira in obdeluje do preklica, oz. največ 30 let, skladno z določbami zakonodaje o varovanju osebnih podatkov. Vodenje, nadzor in upravljanje zbirke osebnih podatkov ter posredovanje podatkov tretjim natančno določata Pravilnik VOP podjetja GA Adriatic d.o.o. in Katalog osebnih podatkov, ki jih upravlja GA Adriatic d.o.o.. Več o splošnih informacijah o varstvu podatkov si lahko preberem <a href="https://www.renault.si/splosno-vop" target="_blank">TUKAJ</a>. V primeru zlorabe mojih podatkov se lahko obrnem na GA Adriatic d.o.o. na e-naslov: <a href="mailto:vop.renault@renault.si">vop.renault@renault.si</a>.'
            }
          },
          checkboxErrors: {
            smsCheckboxError: 'Za izbrani način prejemanja sporočil SMS je obvezen vnos telefonske številke. Prosimo, izpolnite polje Telefon zgoraj.',
            telephoneCheckboxError: 'Za izbrani način prejemanja sporočil TELEFON je obvezen vnos telefonske številke. Prosimo, izpolnite polje Telefon zgoraj.',
            postaCheckboxError: 'Za izbrani način prejemanja sporočil OBIČAJNA POŠTA je obvezen vnos naslova. Prosimo, izpolnite polja Ulica in hišna številka, Poštna številka in Pošta zgoraj.',
            emailCheckboxError: 'Za izbrani način prejemanja sporočil E-POŠTA je obvezen vnos naslova e-pošte. Prosimo, izpolnite polje E-pošta zgoraj.',
            emailError: 'Način prejemanja sporočil preko E-pošte je obvezen.',
            emailClickedError: 'Označili ste DA pri vrsti sporočila E-novice, zato je polje E-POŠTA obvezno.',
            allChannelsNoError: 'Ker ste pri vseh vrstah sporočil označili NE, izbira načina obveščanja ni mogoča.',
            channelsYesandTypesNoError: 'Označili ste DA pri najmanj eni vrsti sporočil, zato prosimo izberite najmanj en način obveščanja.',
            noCheckboxSelectedError: 'Označili ste DA pri najmanj eni vrsti sporočil, zato prosimo izberite najmanj en način obveščanja.'
          },
          legalInfoLabel: 'Seznanjen(a) sem z informacijami o obdelavi osebnih podatkov GA Adriatic d.o.o.',
          legalInfoDisclamer: 'Prosimo, obvezno preberite.',
          legalInfoText: `
          <div>
            <div>1. Upravljavec osebnih podatkov</div>
            <div>
              GA Adriatic d.o.o., Dunajska 22, 1000 Ljubljana, pravna oseba, ki na slovenskem trgu zastopa znamke Renault, Dacia, Nissan in Alpine ter pooblaščeni trgovci znamk, kjer se nahajajo vaši podatki (seznam je objavljen na <a href="https://www.nissan.si/trgovci" target="_blank">www.nissan.si/trgovci</a>) kot skupni upravljavci podatkov.
            </div>
          </div>
          <div>
            Za vsa vprašanja glede obdelave podatkov ali vaših pravic se obrnite na pooblaščeno osebo za zaščito podatkov: <a href="mailto:SI-Nissan-vop@grandautomotive.eu">SI-Nissan-vop@grandautomotive.eu</a>.
          </div>
          <div>
            <div>2. Namen obdelave osebnih podatkov</div>
            <div>
              GA Adriatic d.o.o. sam ali izbrani pooblaščeni trgovec uporabljata vaše osebne podatke, ki ste jih posredovali prek te spletne strani, za namen obdelave vaše zahteve iz tega obrazca, v roku, ki je potreben za izvedbo storitve. Vaši podatki se lahko uporabijo za anketiranje o zadovoljstvu, za pošiljanje komercialnih ponudb za nakup vozil in poprodajnih izdelkov ter informacij o osebnih ugodnostih, dogodkih in drugih aktualnih novic.
            </div>
          </div>
          <div>
            <div>3. Osebni podatki</div>
            <div>
            Osebni podatki ime, priimek, telefonska številka, e-poštni naslov, označeni z (*), so obvezni za obdelavo vaše zahteve iz tega spletnega obrazca; Če jih ne posredujete, vaše zahteve ne moremo obdelati.
            Osebni podatki, obdelani za namene pošiljanja komercialnih ponudb: ime, priimek, naslov, telefonska številka, e-naslov, socio-demografski podatki (če je dovoljeno), zgodovinske informacije o nakupu (če je dovoljeno).
            </div>
          </div>
          <div>
            <div>4. Pravna podlaga</div>
            <div>
              Upravljanje vaših zahtevkov v okviru pogodbene obveznosti (obdelava vaše zahteve, navedene v tem obrazcu), zakonitega interesa (anketa o zadovoljstvu) in vaše privolitve (pošiljanje komercialnih ponudb).
            </div>
          </div>
          <div>
            <div>5. Prejemniki osebnih podatkov</div>
            <div>
              Pooblaščeni trgovci, ki po potrebi izpolnijo navedene namene in ostale tretje osebe v skladu s svojimi pravnimi obveznostmi.
            </div>
          </div>
          <div>
            <div>6. Vaše pravice</div>
            <div>
              Svojo privolitev za obveščanje lahko kadar koli prekličete tako, da o svoji odločitvi pisno ali ustno obvestite pooblaščenega trgovca ali GA Adriatic d.o.o. na elektronski naslov: <a href="mailto:SI-Nissan-AS@grandautomotive.eu">SI-Nissan-AS@grandautomotive.eu</a>.
              Imate pravico do dostopa do vaših osebnih podatkov, popravka, brisanja (razen če je zahteva v nasprotju z veljavno zakonodajo) in nasprotovanja obdelavi vaših osebnih podatkov, vključno s prepovedjo pošiljanja obvestil ter druge pravice, kot je razloženo v razdelku o dodatnih informacijah (v točki 8). V primeru zlorabe vaših podatkov se lahko obrnete na GA Adriatic d.o.o. na e-naslov: <a href="mailto:SI-Nissan-vop@grandautomotive.eu">SI-Nissan-vop@grandautomotive.eu</a>.
              Imate pravico do pravnega varstva. Lahko se obrnete na Informacijskega pooblaščenca RS.
            </div>
          </div>
          <div>
            <div>7. Rok za shranjevanje vaših osebnih podatkov</div>
            <div>
              Osebne podatke, navedene v tej zahtevi, GA Adriatic d.o.o. sam ali izbrani pooblaščeni trgovec uporabljata za obdelavo vaše zahteve, v času, ki je potreben za izvedbo storitve, in jih hranita največ 3 leta. V primeru uporabe vaših podatkov anketiranje o zadovoljstvu se vaši podatki shranjujejo 1 leto po koncu te dejavnosti. V primeru vaše pozitivne privolitve se lahko vaši podatki združijo in povežejo z vašimi drugimi osebnimi podatki, ki jih hranimo. Upravljavec te osebnostne podatke zbira in obdeluje do preklica ali največ 30 let v skladu z določbami zakonodaje o varstvu osebnih podatkov.
            </div>
          </div>
          <div>
            <div>8. Dodatne informacije</div>
            <div>
              Dodatne in podrobnejše informacije o varovanju vaših osebnih podatkov najdete v naši <a href="https://www.nissan.si/splosno-vop.html" target="_blank">Politiki zasebnosti</a>.
            </div>
          </div>`
        },
        hr: {
          user: {
            title: 'Vaši podaci',
            gender: {
              title: 'Status',
              select: 'Odaberite',
              error: 'Molimo da odaberete svoj status.'
            },
            naturalPerson:{
              title: 'Fizička osoba'
            },
            companyName: {
              title: 'Tvrtka',
              error: 'Molimo da unesete ime tvrtke.'
            },
            name: {
              title: 'Ime',
              error: 'Molimo da unesete svoje ime.'
            },
            lastName: {
              title: 'Prezime',
              error: 'Molimo da unesete svoje prezime.'
            },
            business: {
              title: 'Tvrtka'
            },
            email: {
              title: 'E-mail',
              error: 'Molimo da unesete svoj e-mail.'
            },
            confirmEmail: {
              title: 'Potvrdite e-mail',
              error: 'Molimo vas provjerite jeste li ispravno upisali adresu e-maila kako bismo vas lakše kontaktirali i poslali vam ponudu za kupnju vozila.'
            },
            address: {
              title: 'Ulica',
              error: 'Molimo da unesete svoju ulicu.'
            },
            houseNumber: {
              title: 'Kućni broj',
              error: 'Molimo da unesete svoj kućni broj.'
            },
            postalCode: {
              title: 'Poštanski broj',
              error: 'Molimo da unesete svoj poštanski broj.'
            },
            city: {
              title: 'Grad',
              error: 'Molimo da unesete naziv svog grada.'
            },
            phone: {
              title: 'Broj telefona',
              error: 'Molimo da unesete broj svojeg telefona.',
              placeholder: '',
              helpText: 'Zapišite telefonski broj bez razmaka.'
            },
            testDrive: 'Želim probnu vožnju.',
            additional: 'Dodatna poruka'
          },
          gdpr: {
            title: 'Potvrda',
            error: 'Označili ste DA kod najmanje jedne vrste obavijesti zato vas molimo da odaberete najmanje jedan način obavještavanja.',
            terms: {
              helpText: 'Predajom osobnih podataka dopuštam društvu GA Croatia d.o.o. ili odabranom ovlaštenom trgovcu da upotrebljavaju osobne podatke navedene u prethodnom obrascu za potrebe ponude u vremenskom razdoblju potrebnom za izvođenje usluge odnosno najdulje tri godine.',
              helpText2: 'Vođenje, nadzor i upravljanje zbirkom osobnih podataka te posredovanje podataka trećim osobama detaljno određuju Pravilnik ZOP društva GA Croatia d.o.o. i Katalog osobnih podataka, koji vodi GA Croatia d.o.o. Više o općim informacijama o zaštiti podataka možete pročitati <a href="https://www.renault.hr/opce-zop" target="_blank">OVDJE</a>. U slučaju zloupotrebe mojih podataka, mogu se obratiti društvu GA Croatia d.o.o. na e-mail adresu: <a href="mailto:HR-Renault-zop@grandautomotive.eu">HR-Renault-zop@grandautomotive.eu</a>. Imam pravo na pravnu zaštitu. Mogu kontaktirati Agenciju za zaštitu osobnih podataka.'
            },
            comply: {
              title: 'Želite da vam šaljemo poruke o aktualnim ponudama i novostima?',
              yes: 'da',
              no: 'ne',
              error: 'Molimo označite svoj odabir.',
              agree: 'Privola za informiranje'
            },
            types: {
              title: '',
              description: 'Želim primati personalizirane ponude za kupnju vozila i postprodajnih proizvoda i usluga i informacije o personaliziranim pogodnostima i događajima kao i druge aktualne vijesti od GA Croatia d.o.o., koja na hrvatskom tržištu zastupa marke Renault, Dacia, Nissan i od ovlaštenih distributera tih marki, kod kojih se nalaze moji podaci.',
              purchase: {
                title: 'Ponude za kupnju vozila',
                tooltips: 'Želim primati prilagođene ponude za kupnju vozila.',
                error: 'Molimo označite svoj odabir.'
              },
              afs: {
                title: 'Prilagođene ponude',
                tooltips: 'Želim primati ponude za kupnju vozila i postprodajnih proizvoda i usluga koje su personalizirane na temelju mojih podataka koje sam vam dostavio; i informacije o personaliziranim pogodnostima, događajima i drugim aktualnim vijestima.<br><br>Osobni podaci obrađeni u tu svrhu: ime, prezime, adresa, broj telefona, e-mail adresa, socio-demografski podaci, povijesni podaci o kupovnoj aktivnosti.',
                error: 'Molimo označite svoj odabir.'
              },
              personalization: {
                title: 'Profilirane ponude',
                tooltips: 'Dopuštam vam da prikupljate i analizirate moje osobne podatke, prošle kupovine i kupovne navike, moje interese, kako bih stvorili osobni profil, i da mi na temelju toga šaljete odgovarajuće personalizirane poruke i ponude.<br><br>Obrađeni osobni podaci u tu svrhu: ime, prezime, adresa, broj telefona, e-mail adresa, socio-demografski podaci, podaci o prošloj aktivnosti kupnje, online kolačići, podaci o geolokaciji.',
                error: 'Molimo označite svoj odabir.'
              }
            },
            channel: {
              title: '',
              description: 'Dopuštam da me GA Croatia d.o.o. i odabrani ovlašteni trgovac Renault obavještavaju na izabrane načine koje ću označiti u nastavku.',
              email: 'E-MAIL',
              sms: 'SMS',
              phone: 'TELEFON',
              mail: 'OBIČNA POŠTA',
              footer: 'Moja privola za obavješćivanje odnosi se na to pitanje i nije vezana uz ostale sustave u kojima se mogu nalaziti moji osobni podaci.<br><br>Svoju privolu mogu opozvati u svakom trenutku na način da svoju odluku pisanim ili usmenim putem pošaljem ovlaštenom trgovcu Renault ili društvu GA Croatia d.o.o. na e-mail adresu: <a href="mailto:HR-Renault-AS@grandautomotive.eu">HR-Renault-AS@grandautomotive.eu</a>.<br><br>Za podatke koje sam dostavio(la) voditelju obrade podataka mogu zahtjevati: ispravak podataka, brisanje podataka (osim kada je zahtjev u suprotnosti s važećim zakonskim propisima), uvid u podatke i izvor podataka te ograničenje upotrebe podataka. Voditelj obrade podataka moje osobne podatke prikuplja i obrađuje do opoziva ili najduže 30 godina, sukladno zakonskim odredbama o zaštiti osobnih podataka. Vođenje, nadzor i upravljanje zbirkom osobnih podataka te posredovanje podataka trećim osobama detaljno određuju Pravilnik ZOP društva GA Croatia d.o.o. i Katalog osobnih podataka, koji vodi GA Croatia d.o.o. Više o općim informacijama o zaštiti podataka možete pročitati <a href="https://www.renault.hr/opce-zop" target="_blank">OVDJE</a>. U slučaju zloupotrebe mojih podataka, mogu se obratiti društvu GA Croatia d.o.o. na e-mail adresu: <a href="mailto:HR-Renault-zop@grandautomotive.eu">HR-Renault-zop@grandautomotive.eu</a>. Imam pravo na pravnu zaštitu. Mogu kontaktirati Agenciju za zaštitu osobnih podataka.'
            }
          },
          checkboxErrors: {
            smsCheckboxError: 'Za odabrani način primanja SMS poruka obvezno je navesti broj telefona. Molimo da ispunite polje Telefon.',
            telephoneCheckboxError: 'Za odabrani način primanja poruka telefonskim putem obvezno je navesti broj telefona. Molimo da ispunite polje Telefon.​',
            postaCheckboxError: 'Za odabrani način primanja poruka običnom poštom obvezno je navesti ulicu, kućni broj, poštanski broj i grad. Molimo da ispunite polja Ulica i kućni broj, Poštanski broj i Grad.​',
            emailCheckboxError: 'Za odabrani način primanja poruka e-poštom obvezno je navesti e-mail. Molimo da ispunite polje E-mail.​',
            emailError: 'Način primanja poruka putem e-pošte je obavezan.',
            emailClickedError: 'Kod vrste obavijesti označili ste DA za primanje E-novosti, te je polje E-POŠTA obavezno.',
            allChannelsNoError: 'Ako ste kod vrste obavijesti označili NE, odabir načina komunikacije nije moguć.',
            channelsYesandTypesNoError: 'Označili ste s DA barem jednu vrstu poruka te vas zato molimo da odaberete barem jedan način primanja obavijesti.',
            noCheckboxSelectedError: 'Označili ste s DA barem jednu vrstu poruka te vas zato molimo da odaberete barem jedan način primanja obavijesti.'
          },
          legalInfoLabel: 'Upoznat(a) sam s informacijama o obradi osobnih podataka od strane GA Croatia.',
          legalInfoDisclamer: 'Obavezno pročitajte.',
          legalInfoText: `
            <div>
              <div>1. Voditelj obrade podataka</div>
              <div>
                GA Croatia d.o.o., Radnička cesta 47, 10000 Zagreb, pravna osoba koja na hrvatskom tržištu zastupa marke Renault, Dacia, Nissan i ovlašteni distributeri tih marki kod kojih se nalaze vaši podaci (popis je objavljen na <a href="https://www.nissan.hr/trgovci" target="_blank">www.nissan.hr/trgovci</a>) kao zajednički voditelji obrade podataka.
              </div>
            </div>
            <div>
              Za sva pitanja u vezi s obradom podataka ili vašim pravima, molimo kontaktirajte ovlaštenu osobu za zaštitu podataka: <a href="mailto:HR-Nissan-zop@grandautomotive.eu">HR-Nissan-zop@grandautomotive.eu</a>.
            </div>
            <div>
              <div>2. Svrhe obrade osobnih podataka</div>
              <div>
                GA Croatia d.o.o. sam ili izabrani ovlašteni distributer koristite vaše osobne podatke koje ste prenijeli putem ove internetske stranice u svrhu obrade vašeg zahtjeva iz ovog internetskog obrasca u roku potrebnom za izvršenje usluge. Vaši podaci mogu se koristiti za ankete o zadovoljstvu, za slanje komercijalnih ponuda za kupnju vozila i postprodajnih proizvoda, kao i za informacije o osobnim pogodnostima, događajima i drugim aktualnim vijestima.
              </div>
            </div>
            <div>
              <div>3. Osobni podaci</div>
              <div>
              Osobni podaci ime, prezime, broj telefona, e-mail adresu, označeni s (*), potrebni su za obradu vašeg zahtjeva iz ovog internetskog obrasca; ako ih ne navedete, ne možemo obraditi vaš zahtjev.
              Osobni podaci obrađeni u svrhu slanja komercijalnih ponuda: ime, prezime, adresa, telefonski broj, e-mail adresa, socio-demografski podaci (ako je dopušteno), povijesne informacije o kupnji (ako je dopušteno).
              </div>
            </div>
            <div>
              <div>4. Pravna osnova</div>
              <div>
                Upravljanje vašim zahtjevima prema ugovornim obvezama (obrada vašeg zahtjeva navedenog u ovom obrascu), legitimnom interesu (anketa o zadovoljstvu i vašoj privoli (slanje komercijalnih ponuda).
              </div>
            </div>
            <div>
              <div>5. Primatelji osobnih podataka</div>
              <div>
                Ovlašteni trgovci koji po potrebi ispunjavaju navedene svrhe i ostale treće strane u skladu sa svojim zakonskim obvezama.
              </div>
            </div>
            <div>
              <div>6. Vaša prava</div>
              <div>
                Vašu privolu možete opozvati u svakom trenutku na način da vašu odluku pisanim ili usmenim putem priopćite ovlaštenom distributeru ili društvu GA Croatia, d.o.o. na e-mail adresu: <a href="mailto:HR-Nissan-AS@grandautomotive.eu">HR-Nissan-AS@grandautomotive.eu</a>.
                Imate pravo pristupa vašim osobnim podacima, ispravljanju, brisanju (osim ako je zahtjev u suprotnosti s važećim zakonom) i pravo na prigovor obradi vaših osobnih podataka, uključujući zabranu slanja obavijesti i druga prava, kako je objašnjeno u odjeljku o dodatnim informacijama (točka 8). U slučaju zloupotrebe vaših podataka, možete kontaktirati GA Croatia d.o.o. na e-mail adresu: <a href="mailto:HR-Nissan-zop@grandautomotive.eu">HR-Nissan-zop@grandautomotive.eu</a>.
                Imate pravo na pravnu zaštitu. Možete kontaktirati Agenciju za zaštitu osobnih podataka (AZOP).
              </div>
            </div>
            <div>
              <div>7. Rok za pohranjivanje vaših osobnih podataka</div>
              <div>
                Osobne podatke navedene u ovom zahtjevu, GA Croatia d.o.o. sam ili odabrani ovlašteni trgovac koriste za obradu Vašeg zahtjeva u vrijeme koje je potrebno za obavljanje usluge i čuvaju ih najduže 3 godine. U slučaju korištenja vaših podataka za anketiranje o zadovoljstvu vaši podaci se čuvaju 1 godinu nakon završetka ove aktivnosti. U slučaju vaše pozitivne privole, vaši podaci mogu se objediniti i povezati s vašim drugim osobnim podacima koje čuvamo. Voditelj obrade prikuplja i obrađuje te osobne podatke do opoziva odnosno najviše 30 godina, u skladu s odredbama zakonodavstva o zaštiti osobnih podataka.
              </div>
            </div>
            <div>
              <div>8. Dodatne informacije</div>
              <div>
                Za više i detaljnije informacije o zaštiti vaših osobnih podataka, pogledajte našu <a href="https://www.nissan.hr/opce-zop" target="_blank">Politiku privatnosti</a>.
              </div>
            </div>`
        },
        rs: {
          user: {
            title: 'Moji podaci',
            gender: {
              title: 'Oslovljavanje',
              select: 'Izaberite',
              error: 'Unos oslovljavanja je obavezan.'
            },
            naturalPerson:{
              title: 'Fizička osoba'
            },
            companyName: {
              title: 'Kompanija',
              error: 'Unos imena kompanije je obavezan.'
            },
            name: {
              title: 'Ime:',
              error: 'Upišite svoje ime.'
            },
            lastName: {
              title: 'Prezime:',
              error: 'Upišite svoje prezime.'
            },
            business: {
              title: 'Podjetje'
            },
            email: {
              title: 'E-adresa:',
              error: 'Upišite e-mail adresu.'
            },
            repeatEmail: {
              title: 'Potvrdite a-adresu:',
              error: 'Molimo vas da proverite da li ste ispravno upisali e-adresu kako bismo vas lakše kontaktirali i pozvali na probnu vožnju.'
            },
            address: {
              title: 'Ulica:',
              error: 'Upišite naziv ulice.'
            },
            houseNumber: {
              title: 'Kućni broj:',
              error: 'Upišite svoj kućni broj.'
            },
            postalCode: {
              title: 'Poštanski broj:',
              error: 'Upišite svoj poštanski broj.'
            },
            city: {
              title: 'Grad:',
              error: 'Upišite naziv svog grada.'
            },
            phone: {
              title: 'Broj telefona:',
              error: 'Upišite telefonski broj bez razmaka.',
              placeholder: '',
              helpText: 'Upišite telefonski broj bez razmaka.'
            }
          },
          gdpr: {
            title: 'Potvrda',
            error: 'Označili ste DA kod najmanje jedne vrste obaveštenja zato vas molimo da odaberete najmanje jedan način obaveštavanja.',
            terms: {
              helpText: 'Posredovanjem ličnih podataka dozvoljavam da KEOS, d.o.o. sam ili preko izabranog ovlašćenog distributera Renault upotrebljava lične podatke, navedene u gornjem obrascu, u svrhu izvršenja usluge u vremenskom periodu potrebnom za izvršenje usluge, odnosno najduže 3 godine.',
              helpText2: 'Vođenje, kontrola i upravljanje bazom podataka o ličnosti kao i posredovanje podataka trećim licima precizno određuje Pravilnik ZLP kompanije KEOS, d.o.o. i Katalog podataka o ličnosti, kojim upravlja KEOS, d.o.o. Više o opštim informacijama o zaštiti ličnih podataka mogu da pročitam <a href="http://www.renault.rs/opste-zlp.html" target="_blank">OVDE</a>. U slučaju zloupotrebe mojih podataka mogu da se obratim kompaniji Keos d.o.o. na e-adresu: <a href="mailto:zlp.renault@keos.rs">zlp.renault@keos.rs</a>. Imam pravo na sudsku zaštitu i obračanje Povereniku za informacije od javnog značaja i zaštitu podataka o ličnosti.'
            },
            comply: {
              title: 'Želite, da vam pošiljamo sporočila o aktualnih ponudbah in novostih?',
              yes: 'da',
              no: 'ne',
              error: 'Prosimo označite svoj izbor.',
              agree: 'SAGLASNOST ZA OBAVEŠTAVANJE'
            },
            types: {
              title: '',
              description: 'Želim da primam personalizovane ponude za kupovinu vozila i postprodajnih proizvoda i usluga kao i informacije o personalizovanim pogodnostima, događajima i druge aktuelne vesti od Adriatic Auto d.o.o., koja na tržištu Srbije zastupa marku Nissan, uz ovlaštene distributere ovih marki kod kojih se nalaze moji podaci.',
              purchase: {
                title: 'Ponude za kupovinu vozila',
                tooltips: 'Želim da primam prilagođene prodajne ponude za kupovinu vozila.',
                error: 'Molimo označite svoj izbor.'
              },
              afs: {
                title: 'Prilagođene ponude',
                tooltips: 'Želim da dobijem ponude za kupovinu vozila i postprodajnih proizvoda i usluga koji su personalizovani na osnovu mojih podataka koje sam vam dao; i informacije o personalizovanim pogodnostima, događajima i drugim aktuelnim vestima.<br><br>Obrađeni lični podaci u tu svrhu: ime, prezime, adresa, broj telefona, e-mail adresa, socio-demografski podaci, podaci o istorijskim kupovnim aktivnostima.',
                error: 'Molimo označite svoj izbor.'
              },
              personalization: {
                title: 'Profilisane ponude',
                tooltips: 'Dopuštam vam da prikupljate i analizirate moje lične podatke, prethodne kupovine i kupovne navike, moje interese, kako bih stvorili lični profil,  na osnovu kojeg mi šaljete personalizovane poruke i ponude.<br><br>Lični podaci obrađeni u tu svrhu: ime, prezime, adresa, broj telefona, e-mail adresa, socio-demografski podaci, podaci o prošloj aktivnosti kupovine, onlajn kolačići, podaci o geolokaciji.',
                error: 'Molimo označite svoj izbor.'
              }
            },
            channel: {
              title: '',
              description: 'Dozvoljavam da me KEOS d.o.o. i izabrani ovlaščeni distributer Renault obaveštavate na izabrane načine koje ću označiti ispod.',
              email: 'E-ADRESA',
              sms: 'SMS',
              phone: 'TELEFON',
              mail: 'OBIČNA POŠTA',
              footer: 'Moja saglasnost za obaveštavanje se odnosi na ovo potraživanje i nije vezana za druge sisteme u kojima se mogu nalaziti moji lični podaci.<br><br>Svoju saglasnost mogu da otkažem u bilo kom trenutku tako što svoju odluku pisano ili usmeno saopštim ovlašćenom distributeru Renault ili kompaniji KEOS, d.o.o. na e-adresu: <a href="mailto:renault.kontakt@keos.rs">renault.kontakt@keos.rs</a>.<br><br>Za podatke, koje sam dostavio(la) rukovaocu podataka, mogu da zahtevam: ispravku podataka, brisanje podataka (osim ako je zahtev u suprotnosti sa važećim zakonodavstvom), uvid u podatke i izvor podataka kao i ograničenje upotrebe podataka. Rukovalac moje lične podatke prikuplja i obrađuje do opoziva, ili najduže 30 godina, u skladu sa odredbama zakonodavstva o zaštiti podataka o ličnosti. Vođenje, kontrola i upravljanje bazom podataka ličnosti kao i posredovanje podataka trećim licima precizno određuje Pravilnik ZLP kompanije KEOS, d.o.o. i Katalog podataka o ličnosti, kojima upravlja KEOS, d.o.o. Više o opštim informacijama o zaštiti ličnih podataka mogu da pročitam <a href="http://www.renault.rs/opste-zlp.html" target="_blank">OVDE</a>. U slučaju zloupotrebe mojih podataka mogu da se obratim kompaniji KEOS d.o.o. na e-adresu: <a href="mailto:zlp.renault@keos.rs">zlp.renault@keos.rs</a>. Imam pravo na sudsku zaštitu i obračanje Povereniku za informacije od javnog značaja i zaštitu podataka o ličnosti.'
            }
          },
          checkboxErrors: {
            smsCheckboxError: 'Za izabrani način primanja SMS poruka obavezno je navesti broj telefona. Molimo da ispunite polje Telefon.​',
            telephoneCheckboxError: 'Za izabrani način primanja poruka telefonskim putem obavezno je navesti broj telefona. Molimo da ispunite polje Telefon.​',
            postaCheckboxError: 'Za izabrani način primanja poruka običnom poštom obavezno je navesti ulicu, kućni broj, poštanski broj i grad. Molimo da ispunite polja Ulica i kućni broj, Poštanski broj i Grad.​',
            emailCheckboxError: 'Za izabrani način primanja poruka e-poštom obavezno je navesti e-adresu. Molimo da ispunite polje E-adresa.​',
            emailError: 'Primanja poruka putem e-pošte je obavezan kanal.',
            emailClickedError: 'Ako ste kod vrste obaveštavanja  označili sa DA  primanje E-novosti,  polje E-ADRESA je obavezno.',
            allChannelsNoError: 'Ako ste kod vrste obaveštavanja označili NE, izbor načina komunikacije nije moguć.',
            channelsYesandTypesNoError: 'Označili ste sa DA bar jednu vrstu poruka i vas zato molimo da izaberete bar jedan način primanja obaveštenja.',
            noCheckboxSelectedError: 'Označili ste sa DA bar jednu vrstu poruka i vas zato molimo da izaberete bar jedan način primanja obaveštenja.'
          },
          legalInfoLabel: 'Upoznat(a) sam sa informacijama o obradi ličnih podataka od strane Adriatic Auto d.o.o.',
          legalInfoDisclamer: 'Obavezno pročitajte.',
          legalInfoText: `
          <div>
            <div>1. Rukovalac podataka o ličnosti</div>
            <div>
            ADRIATIC AUTO, d.o.o., Milutina Milankovića 7v, 11070 Beograd, pravno lice koje na tržištu Srbije zastupa marku Nissan i ovlašćeni distributeri marke kod kojih se nalaze vaši podaci (spisak je
              objavljen na <a href="http://www.nissan.rs/trgovci" target="_blank">www.nissan.rs/trgovci</a>) kao zajednički Raukovaoci podataka.
            </div>
          </div>
          <div>
          Za sva pitanja u vezi obrade podataka ili vaših prava, molimo vas da kontaktirate osobu ovlašćenu za zaštitu podataka: <a href="mailto:RS-Nissan-zlp@grandautomotive.eu">RS-Nissan-zlp@grandautomotive.eu</a>.
          </div>
          <div>
            <div>2. Svrhe obrade podataka o ličnosti</div>
            <div>
            ADRIATIC AUTO, d.o.o. sam ili izabrani ovlašćeni distributer koriste vaše lične podatke posredovane putem ove internet stranice u svrhu sprovođenja vašeg zahteva iz ovog obrasca, u vremenskom periodu potrebnom za izvršenje usluge. Vaši podaci se mogu koristiti za anketiranje o zadovoljstvu, za slanje komercijalnih ponuda za kupovinu vozila i postprodajnih proizvoda kao i informacija o personalizovanim pogodnostima, događajima i drugim aktuelnim vestima.
            </div>
          </div>
          <div>
            <div>3. Podaci o ličnosti</div>
            <div>
            Lični podaci ime, prezime, broj telefona, e-mail adresa označeni sa (*) su obavezni za obradu vašeg zahteva podnetog na internet sajtu; ukoliko ih ne dostavite, nismo u mogućnosti da obradimo vaš zahtev. Obrađeni lični podaci u svrhu slanja komercijalnih ponuda: ime, prezime, adresa, broj telefona, e-mail adresa, socio-demografski podaci (ako je dozvoljeno), podaci o istorijskim kupovnim aktivnostima (ako je dozvoljeno).
            </div>
          </div>
          <div>
            <div>4. Pravna osnova</div>
            <div>
            Upravljanje vašim zahtevima u kontekstu ugovornih mera (obrada vašeg zahteva navedenog u ovom obrascu), legitimnog interesa (anketiranje o zadovoljstvu) i vaše saglasnosti (slanje komercijalnih ponuda).
            </div>
          </div>
          <div>
            <div>5. Primatelji ličnih podataka</div>
            <div>
            Ovlašćeni distributeri, koji po potrebi ispunjavaju navedene svrhe i ostale treće strane u skladu sa svojim zakonskim obavezama.
            </div>
          </div>
          <div>
            <div>6. Vaša prava</div>
            <div>
            Svoju saglasnost za primanje poruka možete da otkažete u bilo kom trenutku tako što svoju odluku pisano ili usmeno saopštite ovlašćenom distributeru ili kompaniji ADRIATIC AUTO, d.o.o. na e-adresu: <a href="mailto:RS-Nissan-AS@grandautomotive.eu">RS-Nissan-AS@grandautomotive.eu</a>.
            Imate pravo pristupa vašim ličnim podacima, ispravljanje, brisanje (osim ako je zahtev u suprotnosti sa važećim zakonodavstvom) i protivljenje obradi vaših ličnih podataka, uključujući zabranu slanja obaveštenja i druga prava, kao što je objašnjeno u odeljku Dodatne informacije (u tački 8). U slučaju zloupotrebe vaših podataka možete da se obratite Adriatic Auto d.o.o. na e-adresu: <a href="mailto:RS-Nissan-zlp@grandautomotive.eu">RS-Nissan-zlp@grandautomotive.eu</a>.
            Imate pravo na sudsku zaštitu i obraćanje Povereniku za informacije od javnog značaja i zaštitu podataka o ličnosti.
            </div>
          </div>
          <div>
            <div>7. Rok čuvanja vaših ličnih podataka</div>
            <div>
            Lične podatke navedene u ovom zahtevu, ADRIATIC AUTO, d.o.o. sam ili izabrani ovlašteni distributer upotrebljavaju u svrhu obrade vašeg zahteva u vremenskom periodu potrebnom za izvršenje usluge, i čuvaju ih najduže 3 godine. U slučaju korišćenja vaših podataka za anketiranje o zadovoljstvu vaši podaci se čuvaju 1 godinu nakon završetka ove aktivnosti. U slučaju vaše pozitivne saglasnosti vaši podaci se mogu objediniti i povezati s vašim drugim ličnim podacima koje čuvamo. Rukovalac te podatke o ličnosti prikuplja i obrađuje do opoziva, ili najduže 30 godina, u skladu sa odredbama zakonodavstva o zaštiti podataka o ličnosti.
            </div>
          </div>
          <div>
            <div>8. Dodatne informacije</div>
            <div>
            Dodatne i detaljne informacije o zaštiti ličnih podataka možete pronaći u našoj <a href="https://www.nissan.rs/opste-zlp.html" target="_blank">Politici privatnosti</a>.
            </div>
          </div>`
        }
      }

    }


  }
})
