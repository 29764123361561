<template>
<div>
  <!-- <div class="section">
      <h3 class="section-heading" v-html="translationsComputed.user.title"></h3>
    </div> -->

  <!-- B2B -->
  <div class="menu-wrap">
    <ul class="range-tab row">
      <li class="tab-holder" :class="{active: !isCustomerCompany}" @click="isCustomerCompany = false"><span class="tab-text">{{translationsComputed.user.naturalPerson.title}}</span></li>
      <!--
            -->
      <li class="tab-holder" :class="{active: isCustomerCompany}" @click="isCustomerCompany = true"><span class="tab-text">{{translationsComputed.user.companyName.title}}</span></li>
    </ul>
  </div>
  <!-- IME PODJETJA -->
  <div class="textfield section" v-if="isCustomerCompany">
    <div class="form-group required" aria-required="true">
      <label for="companyName" v-html="translationsComputed.user.companyName.title"></label>
      <div class="form-group-container">
        <input id="companyName" name="companyName" v-model="companyName" type="text" :class="{isInvalid: $v.companyName.$error || (submitButttonClicked && !$store.state.validation.companyName)}" @blur="$v.companyName.$touch(), fieldValidations($v.companyName.$invalid, 'companyName')"
          @input="fieldValidations($v.companyName.$invalid, 'companyName')">
        <span class="error-message" v-if="$v.companyName.$error || (submitButttonClicked && !$store.state.validation.companyName)" v-html="translationsComputed.user.companyName.error"></span>
      </div>
    </div>
  </div>



  <!-- NAZIV -->
  <div class="section dropdown">
    <div class="form-group required" aria-required="true">
      <label for="title" v-html="translationsComputed.user.gender.title"></label>
      <div class="custom-selectbox">
        <select name="title" id="title" v-model="userTitle" @change="$store.state.validation.title = true">
          <option class="selected-value" selected="selected" disabled="disabled" v-html="translationsComputed.user.gender.select"></option>
          <option v-for="(title, index) in titlesComputed" :key="index">{{ title }}</option>
        </select>
        <span class="selectedValue" v-if="$store.state.form.user.title === ''" :class="{isInvalid: !$store.state.validation.title && submitButttonClicked}" v-html="translationsComputed.user.gender.select"></span>
        <span class="selectedValue" v-else :class="{isInvalid: !$store.state.validation.title && submitButttonClicked}">{{ $store.state.form.user.title }}</span>
        <span class="error-message" v-if="!$store.state.validation.title && submitButttonClicked" v-html="translationsComputed.user.gender.error"></span>
      </div>
    </div>
  </div>
  <!-- IME -->
  <div class="textfield section">
    <div class="form-group required" aria-required="true">
      <label for="name" v-html="translationsComputed.user.name.title"></label>
      <div class="form-group-container">
        <input id="name" name="name" v-model="name" required="" type="text" :class="{isInvalid: $v.name.$error || (submitButttonClicked && !$store.state.validation.name)}" @blur="$v.name.$touch(), fieldValidations($v.name.$invalid, 'name')" @input="fieldValidations($v.name.$invalid, 'name')">
        <span class="error-message" v-if="$v.name.$error || (submitButttonClicked && !$store.state.validation.name)" v-html="translationsComputed.user.name.error"></span>
      </div>
    </div>
  </div>
  <!-- PRIIMEK -->
  <div class="textfield section">
    <div class="form-group required">
      <label for="last-name" v-html="translationsComputed.user.lastName.title"></label>
      <div class="form-group-container">
        <input id="last-name" name="last-name" v-model="last_name" type="text" :class="{isInvalid: $v.last_name.$error || (submitButttonClicked && !$store.state.validation.lastName)}" @blur="$v.last_name.$touch(), fieldValidations($v.last_name.$invalid, 'lastName')"
          @input="fieldValidations($v.last_name.$invalid, 'lastName')">
        <span class="error-message" v-if="$v.last_name.$error || (submitButttonClicked && !$store.state.validation.lastName)" v-html="translationsComputed.user.lastName.error"></span>
      </div>
    </div>
  </div>
  <!-- BUSINESS -->
  <!-- <div class="textfield section">
      <div class="form-group">
        <label for="business" v-html="translationsComputed.user.business.title"></label>
        <div class="form-group-container">
          <input id="business" name="business" v-model="$store.state.form.user.business" type="text">
        </div>
      </div>
    </div> -->
  <!-- EMAIL -->
  <div class="textfield section">
    <div class="form-group required">
      <label for="email" v-html="translationsComputed.user.email.title"></label>
      <div class="form-group-container">
        <input id="email" name="email" v-model="email" type="email" :class="{isInvalid: $v.email.$error || (submitButttonClicked && !$store.state.validation.email)}" @blur="$v.email.$touch(), fieldValidations($v.email.$invalid, 'email')" @input="fieldValidations($v.email.$invalid, 'email')">
        <span class="error-message" v-if="$v.email.$error || (submitButttonClicked && !$store.state.validation.email)" v-html="translationsComputed.user.email.error"></span>
      </div>
    </div>
  </div>
  <!-- CONFIRM EMAIL -->
  <!-- <div class="textfield section">
      <div class="form-group required">
        <label for="email" v-html="translationsComputed.user.confirmEmail.title"></label>
        <div class="form-group-container">
          <input id="email" name="email" v-model="confirmEmail" type="email" :class="{isInvalid: $v.confirmEmail.$error || (submitButttonClicked && !$store.state.validation.confirmEmail)}" @blur="$v.confirmEmail.$touch(), fieldValidations($v.confirmEmail.$invalid, 'confirmEmail')" @input="fieldValidations($v.confirmEmail.$invalid, 'confirmEmail')">
          <span class="error-message" v-if="$v.confirmEmail.$error || (submitButttonClicked && !$store.state.validation.confirmEmail)" v-html="translationsComputed.user.confirmEmail.error"></span>
        </div>
      </div>
    </div> -->
  <!-- ADDRESS -->
  <div class="textfield section">
    <div class="form-group">
      <label for="street" v-html="translationsComputed.user.address.title"></label>
      <div class="form-group-container">
        <input id="street" name="street" v-model="street" type="text" :class="{isInvalid: (submitButttonClicked && !$store.state.validation.street && $store.state.form.gdpr.obicajnaPosta) || (!$store.state.validation.street && $store.state.form.gdpr.obicajnaPosta)}"
          @blur="fieldValidations($v.street.$invalid, 'street'), fieldValidations($v.city.$invalid, 'city'), fieldValidations($v.street_number.$invalid, 'streetNumber')" @input="fieldValidations($v.street.$invalid, 'street')">
        <span class="error-message" v-if="(submitButttonClicked && !$store.state.validation.street && $store.state.form.gdpr.obicajnaPosta) || (!$store.state.validation.street && $store.state.form.gdpr.obicajnaPosta)" v-html="translationsComputed.user.address.error"></span>
      </div>
    </div>
  </div>
  <!-- HOUSE NUMBER -->
  <div class="textfield section">
    <div class="form-group">
      <label for="street-number" v-html="translationsComputed.user.houseNumber.title"></label>
      <div class="form-group-container">
        <input id="street-number" name="street-number" v-model="street_number" type="text" :class="{isInvalid: (submitButttonClicked && !$store.state.validation.streetNumber && $store.state.form.gdpr.obicajnaPosta) || (!$store.state.validation.streetNumber && $store.state.form.gdpr.obicajnaPosta)}"
          @blur="fieldValidations($v.street_number.$invalid, 'streetNumber'), fieldValidations($v.city.$invalid, 'city'), fieldValidations($v.street.$invalid, 'street')" @input="fieldValidations($v.street.$invalid, 'street')">
        <span class="error-message" v-if="(submitButttonClicked && !$store.state.validation.streetNumber && $store.state.form.gdpr.obicajnaPosta) || (!$store.state.validation.streetNumber && $store.state.form.gdpr.obicajnaPosta)" v-html="translationsComputed.user.houseNumber.error"></span>
      </div>
    </div>
  </div>
  <!-- POSTAL CODE -->
  <div class="textfield section">
    <div class="form-group">
      <label for="postal-number" v-html="translationsComputed.user.postalCode.title"></label>
      <div class="form-group-container">
        <input id="postal-number" name="postal-number" v-model="postal_number" type="text" :class="{isInvalid: !$store.state.validation.postalNumber || $v.postal_number.$error }" @blur="$v.postal_number.$touch(), fieldValidations($v.postal_number.$invalid, 'postalNumber')"
          @input="fieldValidations($v.postal_number.$invalid, 'postalNumber')">
        <span class="error-message" v-if="!$store.state.validation.postalNumber || $v.postal_number.$error" v-html="translationsComputed.user.postalCode.error"></span>
      </div>
    </div>
  </div>
  <!-- CITY -->
  <div class="textfield section">
    <div class="form-group">
      <label for="city" v-html="translationsComputed.user.city.title"></label>
      <div class="form-group-container">
        <input id="city" name="city" v-model="city" type="text" :class="{isInvalid: (submitButttonClicked && !$store.state.validation.city && $store.state.form.gdpr.obicajnaPosta) || (!$store.state.validation.city && $store.state.form.gdpr.obicajnaPosta)}"
          @blur="fieldValidations($v.city.$invalid, 'city'), fieldValidations($v.street_number.$invalid, 'streetNumber'), fieldValidations($v.street.$invalid, 'street')" @input="fieldValidations($v.city.$invalid, 'city')">
        <span class="error-message" v-if="(submitButttonClicked && !$store.state.validation.city && $store.state.form.gdpr.obicajnaPosta) || (!$store.state.validation.city && $store.state.form.gdpr.obicajnaPosta)" v-html="translationsComputed.user.city.error"></span>
      </div>
    </div>
  </div>
  <!-- PHONE -->
  <div class="textfield section">
    <div class="form-group">
      <label for="telephone" v-html="translationsComputed.user.phone.title"></label>
      <div class="form-group-container">
        <input id="telephone" name="telephone" v-model="telephone" type="text" :class="{isInvalid:(submitButttonClicked && !$store.state.validation.telephone && $store.state.form.gdpr.telefon) || (submitButttonClicked && !$store.state.validation.telephone && $store.state.form.gdpr.sms) || (!$store.state.validation.telephone && $store.state.form.gdpr.telefon) || (!$store.state.validation.telephone && $store.state.form.gdpr.sms)}"
          @blur="fieldValidations($v.telephone.$invalid, 'telephone')" @input="fieldValidations($v.telephone.$invalid, 'telephone')" :placeholder="translationsComputed.user.phone.placeholder">
        <span class="error-message" v-if="(submitButttonClicked && !$store.state.validation.telephone && $store.state.form.gdpr.telefon) || (submitButttonClicked && !$store.state.validation.telephone && $store.state.form.gdpr.sms) || (!$store.state.validation.telephone && $store.state.form.gdpr.telefon) || (!$store.state.validation.telephone && $store.state.form.gdpr.sms)"
          v-html="translationsComputed.user.phone.error"></span>
          <span class="error-message" v-if="!$v.telephone.paidPhoneNumber && !(submitButttonClicked && !$store.state.validation.telephone)"
          v-html="translationsComputed.user.phone.helpText090"></span>
      </div>
      <span class="help-block" v-if="!$v.telephone.$error && !(submitButttonClicked && !$store.state.validation.telephone)">
        <p v-html="translationsComputed.user.phone.helpText"></p>
      </span>
    </div>
  </div>
  <!-- <div class="checkbox section">
      <div class="form-group checkbox">
        <div class="checkbox-container">
          <input id="checkbox" v-model="testDrive" name="TESTDRIVE_REQUEST" data-validation-notblank="" value="true" data-request-variable="" type="checkbox">
          <label for="checkbox" v-html="translationsComputed.user.testDrive"></label>
        </div>
      </div>
    </div>
    <div class="textarea section">
      <div class="form-group " aria-required="false">
        <label for="textarea" v-html="translationsComputed.user.additional"></label>
          <div class="form-group-container">
            <textarea id="textarea" name="FREE_MESSAGE" v-model="message" @blur="$store.state.form.service.notes = message" placeholder="" data-storage-key="" data-validation-customregex-pattern="" data-validation-customregex="" data-request-variable="" data-validation-notblank=""></textarea>
        </div>
      </div>
    </div> -->

  <!-- gdpr -->

  <div>
    <div class="grid-row">
      <div>
        <div class="gdpr-header section">
          <h3 style="text-transform: uppercase;"></h3>
          <p style="font-size: 15px; font-weight: 700;" v-html="translationsComputed.gdpr.types.title"></p>
          <p style="font-size: 13px;" v-html="translationsComputed.gdpr.types.description"></p>
        </div>
      </div>
    </div>
    <div class="grid-row">
      <div>
        <div class="c_019" ontouchstart="">
          <fieldset>
            <div class="checkbox section">
              <div class="form-group">
                <div class="form-group">
                  <div class="col-8" style="position:relative;">
                    <p style="font-size:15px; font-weight: 700;" for="nakup" class="nakup-label">{{translationsComputed.gdpr.channel.email}}</p>
                    <span class="error-message" style="font-size: 10px;" v-if="$store.state.buttons.poprodajniIzdelkiBtn.error" v-html="translationsComputed.gdpr.types.afs.error"></span>
                  </div>
                  <div class="form-check form-check-inline col-2 checkbox-margin">
                    <label class="form-check-label" for="e-posta-yes">{{translationsComputed.gdpr.comply.yes}}
                      <input class="form-check-input" type="radio" v-model="gdprEmail" :value="true" name="e-posta" id="e-posta-yes" @change="checkValid(), fieldValidations(gdprEmail === null, 'gdprEmail')">
                      <span class="gdpr-radio-button" :class="{ isInvalid: submitButttonClicked && gdprEmail === null }"></span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline col-2 checkbox-margin">
                    <label class="form-check-label" for="e-posta-ne">{{translationsComputed.gdpr.comply.no}}
                      <input class="form-check-input" type="radio" v-model="gdprEmail" :value="false" name="e-posta" id="e-posta-ne" @change="checkValid(), fieldValidations(gdprEmail === null, 'gdprEmail')">
                      <span class="gdpr-radio-button" :class="{ isInvalid: submitButttonClicked && gdprEmail === null }"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="checkbox section">
              <div class="form-group">
                <div class="form-group">
                  <div class="col-8" style="position:relative;">
                    <p style="font-size:15px; font-weight: 700;" for="nakup" class="nakup-label">{{translationsComputed.gdpr.channel.sms}}</p>
                  </div>
                  <div class="form-check form-check-inline col-2 checkbox-margin">
                    <label class="form-check-label" for="sms-yes">{{translationsComputed.gdpr.comply.yes}}
                      <input class="form-check-input" type="radio" v-model="gdprSms" :value="true" name="sms" id="sms-yes" @change="checkValid(), checkPhoneAndSms()">
                      <span class="gdpr-radio-button"></span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline col-2 checkbox-margin">
                    <label class="form-check-label" for="sms-ne">{{translationsComputed.gdpr.comply.no}}
                      <input class="form-check-input" type="radio" v-model="gdprSms" :value="false" name="sms" id="sms-ne" @change="checkValid(), checkPhoneAndSms()">
                      <span class="gdpr-radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="checkbox section">
              <div class="form-group">
                <div class="form-group">
                  <div class="col-8" style="position:relative;">
                    <p style="font-size:15px; font-weight: 700;" for="nakup" class="nakup-label">{{translationsComputed.gdpr.channel.phone}}</p>
                  </div>
                  <div class="form-check form-check-inline col-2 checkbox-margin">
                    <label class="form-check-label" for="telefon-yes">{{translationsComputed.gdpr.comply.yes}}
                      <input class="form-check-input" type="radio" v-model="gdprTelefon" :value="true" name="telefon" id="telefon-yes" @change="checkValid(), checkPhoneAndSms()">
                      <span class="gdpr-radio-button"></span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline col-2 checkbox-margin">
                    <label class="form-check-label" for="telefon-ne">{{translationsComputed.gdpr.comply.no}}
                      <input class="form-check-input" type="radio" v-model="gdprTelefon" :value="false" name="telefon" id="telefon-ne" @change="checkValid(), checkPhoneAndSms()">
                      <span class="gdpr-radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="checkbox section">
              <div class="form-group">
                <div class="form-group">
                  <div class="col-8" style="position:relative;">
                    <p style="font-size:15px; font-weight: 700;" for="nakup" class="nakup-label">{{translationsComputed.gdpr.channel.mail}}</p>
                  </div>
                  <div class="form-check form-check-inline col-2 checkbox-margin">
                    <label class="form-check-label" for="posta-yes">{{translationsComputed.gdpr.comply.yes}}
                      <input class="form-check-input" type="radio" v-model="gdprObicajnaPosta" :value="true" name="posta" id="posta-yes" @change="checkValid(), checkMail()">
                      <span class="gdpr-radio-button"></span>
                    </label>
                  </div>
                  <div class="form-check form-check-inline col-2 checkbox-margin">
                    <label class="form-check-label" for="posta-ne">{{translationsComputed.gdpr.comply.no}}
                      <input class="form-check-input" type="radio" v-model="gdprObicajnaPosta" :value="false" name="posta" id="posta-ne" @change="checkValid(), checkMail()">
                      <span class="gdpr-radio-button"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>


            <!-- ERROR MSGS -->
            <div class="btn-error-message" style="top: 0" v-if="($store.state.atLeastOneTypeOfSending && submitButttonClicked && !$store.state.allChannelsNull && checkChannels() && ($store.state.buttons.ponudbeZaNakupBtn.value || $store.state.buttons.poprodajniIzdelkiBtn.value || $store.state.buttons.personalizacijaBtn.value))"
              v-html="translationsComputed.gdpr.error"></div>
            <!-- EMAIL ERROR MSG -->
            <div class="btn-error-message" v-if="$store.state.form.gdpr.ePosta && !$store.state.validation.email" v-html="translationsComputed.checkboxErrors.emailCheckboxError"></div>
            <!-- TELEPHONE ERROR MSG -->
            <div class="btn-error-message" v-if="!$store.state.validation.telephone && $store.state.form.gdpr.telefon" v-html="translationsComputed.checkboxErrors.telephoneCheckboxError"></div>
            <div class="btn-error-message" v-if="!$store.state.validation.telephone && $store.state.form.gdpr.sms" v-html="translationsComputed.checkboxErrors.smsCheckboxError"></div>
            <!-- STREET AND POST NUMBER-OFFICE ERROR MESSAGES -->
            <div class="btn-error-message" v-if="(!$store.state.validation.city && $store.state.form.gdpr.obicajnaPosta) || (!$store.state.validation.streetNumber && $store.state.form.gdpr.obicajnaPosta) || (!$store.state.validation.street && $store.state.form.gdpr.obicajnaPosta)"
              v-html="translationsComputed.checkboxErrors.postaCheckboxError"></div>


            <div class="legal-info-component">
              <div class="legal-info-label" @click="legalInfoExpanded = !legalInfoExpanded" :class="{ 'active' : legalInfoExpanded }" v-html="translationsComputed.legalInfoLabel"></div>
              <div class="legal-info-text-content" v-show="legalInfoExpanded" v-html="translationsComputed.legalInfoText">
              </div>
            </div>
            
            <p class="legal-info-disclamer" v-html="translationsComputed.legalInfoDisclamer">
            </p>

          </fieldset>
        </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
  sameAs,
  requiredIf
} from 'vuelidate/lib/validators'
export default {
  props: [
    'submitButttonClicked',
    'translations',
    'translationsKey'
  ],
  data() {
    return {
      titles: ['G.', 'Ga.'],
      confirmEmail: '',
      message: '',
      legalInfoExpanded: false
    }
  },
  mounted() {
    this.$store.subscribe((mutation, state) => {
      setTimeout(() => {
        this.$emit('change', {
          form: this.$store.state.form,
          isFormValid: this.validateAll()
        });
      }, 100);
    });
  },
  computed: {
    translationsComputed: function() {
      if (this.translationsKey) {
        var texts = _.get(this.$store.state.texts.alltexts, this.translationsKey);
        if (texts) {
          return texts;
        }
      }
      try {
        return this.translations ? JSON.parse(this.translations) : this.$store.state.texts;
      } catch (e) {
        return this.translations ? this.translations : this.$store.state.texts;
      }
    },
    titlesComputed: function() {
      if (this.translationsKey) {
        var list = _.get(this.$store.state.dropdowns.titles, this.translationsKey);
        if (list) {
          return list;
        }
      }
      return this.$store.state.dropdowns.titles.renault.si;
    },
    isCustomerCompany: {
      get() {
        return this.$store.getters.isCustomerCompany
      },
      set(value) {
        this.$store.commit('updateIsCustomerCompany', value)
        this.fieldValidations(this.$v.companyName.$invalid, 'companyName')
      }
    },
    companyName: {
      get() {
        return this.$store.getters.companyName
      },
      set(value) {
        this.$store.commit('updateCompanyName', value)
      }
    },
    // TWO WAY DATA BINDING FOR USER NAME
    userTitle: {
      get() {
        return this.$store.getters.title
      },
      set(value) {
        this.$store.commit('updateTitle', value)
      }
    },
    name: {
      get() {
        return this.$store.getters.name
      },
      set(value) {
        this.$store.commit('updateName', value)
      }
    },
    last_name: {
      get() {
        return this.$store.getters.last_name
      },
      set(value) {
        this.$store.commit('updateLastName', value)
      }
    },
    email: {
      get() {
        return this.$store.getters.email
      },
      set(value) {
        this.$store.commit('updateEmail', value)
      }
    },
    telephone: {
      get() {
        return this.$store.getters.telephone
      },
      set(value) {
        this.$store.commit('updateTelephone', value)
      }
    },
    street: {
      get() {
        return this.$store.getters.street
      },
      set(value) {
        this.$store.commit('updateStreet', value)
      }
    },
    street_number: {
      get() {
        return this.$store.getters.street_number
      },
      set(value) {
        this.$store.commit('updateStreetNumber', value)
      }
    },
    postal_number: {
      get() {
        return this.$store.getters.postal_number
      },
      set(value) {
        this.$store.commit('updatePostalNumber', value)
      }
    },
    city: {
      get() {
        return this.$store.getters.city
      },
      set(value) {
        this.$store.commit('updateCity', value)
      }
    },
    testDrive: {
      get() {
        return this.$store.getters.testDrive
      },
      set(value) {
        this.$store.commit('updateTestDrive', value)
      }
    },
    gdprEmail: {
      get() {
        return this.$store.getters.gdprEmail
      },
      set(value) {
        this.$store.commit('updateGdprEmail', value)
      }
    },
    gdprSms: {
      get() {
        return this.$store.getters.gdprSms
      },
      set(value) {
        this.$store.commit('updateGdprSms', value)
      }
    },
    gdprTelefon: {
      get() {
        return this.$store.getters.gdprTelefon
      },
      set(value) {
        this.$store.commit('updateGdprTelefon', value)
      }
    },
    gdprObicajnaPosta: {
      get() {
        return this.$store.getters.gdprObicajnaPosta
      },
      set(value) {
        this.$store.commit('updateGdprObicajnaPosta', value)
      }
    },
  },
  validations: {
    companyName: {
      required: requiredIf('isCustomerCompany')
    },
    name: {
      required
    },
    last_name: {
      required
    },
    email: {
      required,
      email
    },
    confirmEmail: {
      sameAsEmail: sameAs('email')
    },
    telephone: {
      required: requiredIf(function() {
        return this.$store.state.form.gdpr.telefon || this.$store.state.form.gdpr.sms
      }),
      numeric,
      minLength: minLength(8),
      maxLength: maxLength(13),
      paidPhoneNumber(telephone) {
        if (this.translationsKey != 'renault.si' && this.translationsKey != 'dacia.si') return true;
        return (
          /^(?!090)(?:.*)/.test(telephone) // checks for 090
        );
      }
    },
    street: {
      required: requiredIf(function() {
        return this.$store.state.form.gdpr.obicajnaPosta
      })
    },
    street_number: {
      required: requiredIf(function() {
        return this.$store.state.form.gdpr.obicajnaPosta
      })
    },
    postal_number: {
      // required,
      required: requiredIf(function() {
        return this.$store.state.form.gdpr.obicajnaPosta
      }),
      numeric,
      maxLength: maxLength(5),
      minLength: minLength(4)
    },
    city: {
      required: requiredIf(function() {
        return this.$store.state.form.gdpr.obicajnaPosta
      })
    }
  },
  methods: {
    fieldValidations(validation, storeObj) {
      if (validation === false) {
        this.$store.state.validation[storeObj] = true
      } else {
        this.$store.state.validation[storeObj] = false
      }
    },
    validateAll() {
      if (this.$store.state.buttons.complyBtn.value === true) {
        if (this.$store.state.buttons.ponudbeZaNakupBtn.error === true || this.$store.state.buttons.poprodajniIzdelkiBtn.error === true || this.$store.state.buttons.personalizacijaBtn.error === true) {
          console.log('noup check buttons')
          return false;
        }
        if (this.$store.state.buttons.personalizacijaBtn.value === false && this.$store.state.buttons.poprodajniIzdelkiBtn.value === false && this.$store.state.buttons.ponudbeZaNakupBtn.value === false) {
          this.$store.state.atLeastOneTypeOfSending = true
          this.$store.state.validation.street = true
          this.$store.state.validation.streetNumber = true
          this.$store.state.validation.city = true
          this.$store.state.validation.telephone = true
          console.log(JSON.stringify(this.$store.state.atLeastOneTypeOfSending))
        }

        for (var key2 in this.$store.state.validation) {
          if (this.$store.state.validation[key2] === false) {
            console.log(key2, this.$store.state.validation[key2])
            console.log('noup check fields')
            return false;
          }
        }
        console.log('all true 1')
        return true;
      }
    },
    //GDPR
    checkValid() {
      if (this.$store.state.buttons.personalizacijaBtn.value === false && this.$store.state.buttons.poprodajniIzdelkiBtn.value === false && this.$store.state.buttons.ponudbeZaNakupBtn.value === false) {
        this.$store.state.form.gdpr.ePosta = false
        this.$store.state.form.gdpr.sms = false
        this.$store.state.form.gdpr.telefon = false
        this.$store.state.form.gdpr.obicajnaPosta = false
        // this.$store.state.atLeastOneTypeOfSending = false
      } else {
        this.$store.state.atLeastOneTypeOfSending = true
      }
    },
    checkTypes() {
      if (this.$store.state.buttons.personalizacijaBtn.value === false && this.$store.state.buttons.poprodajniIzdelkiBtn.value === false && this.$store.state.buttons.ponudbeZaNakupBtn.value === false) {
        return true
      } else {
        return false
      }
    },
    checkChannels() {
      if (this.$store.state.form.gdpr.ePosta === true || this.$store.state.form.gdpr.sms === true || this.$store.state.form.gdpr.telefon === true || this.$store.state.form.gdpr.obicajnaPosta === true) {
        return false
      } else {
        return true
      }
    },
    checkMail() {
      if (this.$store.state.form.gdpr.obicajnaPosta) {
        if (this.$store.state.form.user.street === "") {
          this.$store.state.validation.street = false;
          this.$store.state.validation.atLeastOneTypeOfSending = true;
          this.$store.state.streetError = true;
        } else {
          this.$store.state.validation.street = true;
          this.$store.state.validation.atLeastOneTypeOfSending = true;
          this.$store.state.streetError = false;
        }
        if (this.$store.state.form.user.street_number === "") {
          this.$store.state.validation.streetNumber = false;
          this.$store.state.validation.atLeastOneTypeOfSending = true;
          this.$store.state.streetError = true;
        } else {
          this.$store.state.validation.streetNumber = true;
          this.$store.state.validation.atLeastOneTypeOfSending = true;
          this.$store.state.streetError = false;
        }

        if (this.$store.state.form.user.city === "") {
          this.$store.state.validation.city = false;
          this.$store.state.validation.atLeastOneTypeOfSending = true;
          this.$store.state.streetError = true;
        } else {
          this.$store.state.validation.city = true;
          this.$store.state.validation.atLeastOneTypeOfSending = true;
          this.$store.state.streetError = false;
        }

        if (!this.$store.state.form.user.postalNumber) {
          this.$store.state.validation.postalNumber = false;
          this.$store.state.validation.atLeastOneTypeOfSending = true;
          this.$store.state.streetError = true;
        } else {
          this.$store.state.validation.postalNumber = true;
          this.$store.state.validation.atLeastOneTypeOfSending = true;
          this.$store.state.streetError = false;
        }
      } else {
        this.$store.state.validation.street = true;
        this.$store.state.validation.streetNumber = true;
        this.$store.state.validation.postalNumber = true;
        this.$store.state.validation.city = true;
        this.$store.state.streetError = false;
        // this.$store.state.validation.atLeastOneTypeOfSending = false;
      }
    },
    checkSms() {
      if (this.$store.state.form.gdpr.sms) {
        if (this.$store.state.form.user.telephone === "") {
          this.$store.state.validation.telephone = false;
          this.$store.state.validation.atLeastOneTypeOfSending = true;
          this.$store.state.smsError = true;
        } else {
          this.$store.state.validation.telephone = true;
          this.$store.state.validation.atLeastOneTypeOfSending = true;
          this.$store.state.smsError = false;
        }
      } else {
        this.$store.state.validation.telephone = true;
        this.$store.state.smsError = false;
        // this.$store.state.validation.atLeastOneTypeOfSending = false;
      }
    },
    checkPhone() {
      if (this.$store.state.form.gdpr.telefon) {
        if (this.$store.state.form.user.telephone === "") {
          this.$store.state.validation.telephone = false;
          this.$store.state.validation.atLeastOneTypeOfSending = true;
          this.$store.state.telephoneError = true;
        } else {
          this.$store.state.validation.telephone = true;
          this.$store.state.validation.atLeastOneTypeOfSending = true;
          this.$store.state.telephoneError = false;
        }
      } else {
        this.$store.state.validation.telephone = true;
        this.$store.state.telephoneError = false;
        // this.$store.state.validation.atLeastOneTypeOfSending = false;
      }
    },
    checkPhoneAndSms() {
      if (this.$store.state.form.gdpr.telefon || this.$store.state.form.gdpr.sms) {
        if (this.$store.state.form.user.telephone === "") {
          this.$store.state.validation.telephone = false;
          this.$store.state.validation.atLeastOneTypeOfSending = true;
          this.$store.state.telephoneError = true;
        } else {
          this.$store.state.validation.telephone = true;
          this.$store.state.validation.atLeastOneTypeOfSending = true;
          this.$store.state.telephoneError = false;
        }
      } else {
        this.$store.state.validation.telephone = true;
        this.$store.state.telephoneError = false;
        // this.$store.state.validation.atLeastOneTypeOfSending = false;
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.legal-info-disclamer {
  font-size: 13px !important;
}

.legal-info-component {
    border: 1px solid #ececec;
    position: relative;
    font-size: 1.1428571429rem;
    padding: 0.7142857143em;
    margin-top: 20px;
    margin-bottom: 10px;

    .legal-info-label {
        cursor: pointer;
        padding-right: 1.1em;

        &::after {
            content: "";
            position: absolute;
            font-family: icon-Renault-Global;
            padding: 0.7142857143em;
            color: #606164;
            right: 0;
            top: 0 !important;
            transition: all 0.5s;
        }
        &.active::after {
            transform: scaleY(-1);
        }
    }
    .legal-info-text-content {
        font-size: 13px !important;
        & > /deep/ div {
            margin: 1em 0 0;
        }
        /deep/ a, a:hover {
            color: #666;
            text-decoration: underline;
        }
    }    
}

.custom-selectbox {
    width: 30%!important;
    @media only screen and (max-width: 480px) {
        width: 75%!important;
    }
}
.selectedValue::after {
    content: "";
    position: absolute;
    font-family: icon-Renault-Global;
    font-size: 1.1428571429rem;
    border-left: 1px solid #606164;
    padding-left: 0.7142857143em;
    padding-right: 0.7142857143em;
    color: #606164;
    line-height: 1.875 !important;
    right: 0;
    top: 2px!important;
    transform: none !important;
}
//ERROR HANDLING
.isInvalid {
    border: 2px solid red!important;
}
input.isInvalid::placeholder {
    color: red;
}
.error-message {
    display: block;
    color: red;
}

.range-tab {
    list-style: outside none none;
    padding: 0;
    margin: 0;
    border: 1px solid #ececec;
    border-bottom: 0 solid rgb(204, 204, 204);
}

.range-tab > li {
    display: inline-block;
    width: calc(100% / 2);
    background-color: rgb(236, 236, 236);
    border-right: 1px solid rgb(204, 204, 204);
    border-bottom: 0 solid rgb(204, 204, 204);
    font-family: "Renault Bold";
    font-size: 1.083rem;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    position: relative;
    min-height: 1px;
    vertical-align: middle;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    @media screen and (max-width: 990px) {
        width: 100%;
        border-right: 0 none;
    }
    &:last-child {
        border-right: 0 none;
    }
    &.active {
        background-color: rgb(255, 255, 255);
        border-bottom: 0 none;
        &::after {
            content: "";
            width: 92%;
            margin: 0 4%;
            background-color: rgb(255, 204, 51);
            height: 3px;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}

.checkbox {
    font-size: 15px;
    text-align: left;
    margin-top: 0;
}

.form-group.checkbox {
    margin-top: 0;
}

.col-3 {
    padding-bottom: 20px;
}

/* CUSTOM RADIO BUTTONS */
.form-group {
    overflow: hidden;
}
.form-check {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.form-check-inline {
    display: inline-block;
}
label.form-check-label {
    cursor: pointer;
    margin: 0;
    padding: 0;
    font-size: 16px;
    text-transform: uppercase;
}
.form-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
}
input:disabled ~ label {
    cursor: not-allowed;
}
.gdpr-radio-button {
    position: absolute;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    &.isInvalid {
        &:after {
            left: 3px !important;
            top: -2px !important;
        }
    }
}
.form-check:hover input ~ .gdpr-radio-button {
    background-color: #ccc;
}
.gdpr-radio-button:after {
    content: "X";
    position: absolute;
    display: none;
}
.form-check input:checked ~ .gdpr-radio-button:after {
    display: block;
    left: 6px;
    top: 1px;
}
.col-2 {
    width: 12%;
    height: auto;
    max-width: 100%;
    @media screen and (max-width: 959px) {
        width: 50%;
    }
}

.checkbox label::before {
    font-size: 17px;
}

.col-8 {
    padding: 0;
    @media screen and (max-width: 959px) {
        padding: 0;
    }
}

.nakup-label {
    margin-top: 7px;
    line-height: 15px;
    position: relative;
}

//ERROR HANDLING
.isInvalid {
    border: 2px solid red!important;
}
input.isInvalid::placeholder {
    color: red;
}

label {
    &.isInvalid {
        border: none!important;
        &::before {
            border: 2px solid red!important;
        }
    }
}
.error-message {
    display: block;
    color: red;
}
.col-8 {
    width: 74%;
    @media screen and (max-width: 959px) {
        width: 100%;
    }
}
.error-message {
    &::before {
        font-size: 14px;
    }
}
.btn-error-message {
    color: red;
    position: relative;
    font-size: 12px;
    // top: -15px;
}
// CHECKBOX TOP MARGIN
.checkbox-margin {
    margin-top: 10px;
}
.nacin-prejemanja-label {
    margin-top: 0!important;
    padding-left: 15px;
    cursor: pointer;
}
// GDPR INFORMATION ICON
.gdpr-information {
    padding: 5px 5px 5px 10px;
    margin-left: 10px;
    width: 25px;
    height: 25px;
    background-color: #CFC9C4;
    border-radius: 50%;
    color: #fff;
    position: absolute;
    top: -1px;
    z-index: 999;
}

// FLOATING INFORMATION
.floating-info {
    position: absolute;
    padding: 20px 10px;
    border: 1px solid #fc3;
    border-radius: 20px;
    background-color: #fff;
    z-index: 1000;
    width: 40%;
    height: auto;
    &.nakup {
        top: -125px;
        right: 28%;
        @media only screen and (max-width: 992px) {
            top: -75px;
        }
        @media only screen and (max-width: 768px) {
            top: -75px;
            right: 10%;
            width: 75%;
        }
    }
    &.poprodajni-izdelki {
        top: -175px;
        right: 5%;
        @media only screen and (max-width: 992px) {
            top: -125px;
        }
        @media only screen and (max-width: 768px) {
            top: -125px;
        }
    }
    &.personalizacija {
        top: -325px;
        right: 25%;
        @media only screen and (max-width: 992px) {
            top: -275px;
            width: 50%;
        }
        @media only screen and (max-width: 768px) {
            top: -125px;
        }
    }
}

/* Tooltip container */
.tooltip {
    font-family: 'Renault Regular';
    position: relative;
    display: inline-block;
    text-align: center;
    color: #ccc;
    font-size: 14px;
    @media screen and (max-width: 790px) {
        font-size: 22px;
        text-align: left;
        margin-top: 10px;
        display: inline-block;
    }
    .tooltiptext {
        font-size: 14px;
        font-family: 'Renault Regular';
    }
    &::before {
        font-family: icon-Renault-Global;
        content: "";
        padding-right: 5px;
    }
}

/* Tooltip text */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: #fff;
    border: 1px solid #fc3;
    color: #000;
    text-align: center;
    padding: 15px;
    text-align: left;
    margin: 10px;
    border-radius: 6px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    margin-top: -50%;
    z-index: 1;
    @media screen and (max-width: 790px) {
        position: fixed;
        top: 10%;
        left: 10%;
        width: 80%;
        margin-top: 0;
    }
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:focus .tooltiptext,
.tooltip:hover .tooltiptext {
    visibility: visible;
}

.form-group {
    overflow: visible;
}
.form-check-inline+.form-check-inline {
    margin-left: 0;
}

.form-group.required label:after {
    content: "*";
}
</style>
